import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTIONS } from '../userGroups.consts';
import { groupsApi } from '../../../services';
import { deleteUserGroupLoading, deleteUserGroupFailure, deleteUserGroupSuccess } from '../userGroups.actions';
import parseError from '../../__helpers__/parseApiError';

export function * deleteUserGroup(action) {
  try {
    yield put(deleteUserGroupLoading());
    yield call(groupsApi.delete, { id: action.data.displayName }, {ignore_members: "true"});
    yield put(deleteUserGroupSuccess(action.data));
  } catch (e) {
    const errorMessage = parseError(e);
    yield put(deleteUserGroupFailure(errorMessage));
  }
}

export default function * deleteUserGroupSaga() {
  yield takeEvery(ACTIONS.DELETE_USER_GROUP_REQUEST, deleteUserGroup);
}
