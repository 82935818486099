
import Axios from 'axios';
import path from 'path';

/**
 * GETs to endpoint
 * @param { params } props
 * @description params is url params
 */
export function getAllApi({ params } = {}) {
  let url = path.join(this.endpointPrefix, this.endpoint);

  return Axios.request({
    url,
    params,
    method: 'GET',
    baseURL: this.baseURL,
    ...this.requestProps
  });
}
