import { ACTIONS, INITIAL_STATE, PROPS } from './userGroups.consts';
import cloneDeep from 'lodash/cloneDeep';

export default function userGroupReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case ACTIONS.USER_GROUPS_REQUEST_LOADING:
      return setLoading(state);

    case ACTIONS.USER_GROUPS_REQUEST_SUCCESS:
      return setUserGroupList(state, action);

    case ACTIONS.USER_GROUPS_REQUEST_FAILURE:
      return setError(state, action);

    case ACTIONS.CREATE_USER_GROUP_LOADING:
      return setLoading(state);

    case ACTIONS.CREATE_USER_GROUP_SUCCESS:
      return addUserGroupToList(state, action);

    case ACTIONS.CREATE_USER_GROUP_FAILURE:
      return setError(state, action);

    case ACTIONS.DELETE_USER_GROUP_LOADING:
      return setLoading(state);

    case ACTIONS.DELETE_USER_GROUP_SUCCESS:
      return removeUserGroupFromList(state, action);;

    case ACTIONS.DELETE_USER_GROUP_FAILURE:
      return setError(state, action);
  
    case ACTIONS.CLEAR_LOADING_ERROR:
      return clearLoadingAndError(state);

    default:
      return state;
  }
}

function setLoading (state) {
  return { ...state, [PROPS.LOADING]: true };
}

function setError (state, action) {
  return Object.assign({}, state, {
    [PROPS.LOADING]: false,
    [PROPS.ERROR]: action.error
  });
}

function setUserGroupList (state, action) {
  return Object.assign({}, state, {
    [PROPS.LOADING]: false,
    [PROPS.ERROR]: null,
    [PROPS.LIST]: action.data
  });
}

function addUserGroupToList (state, action) {
  const newList = [action.data].concat(state[PROPS.LIST]);
  return Object.assign({}, state, {
    [PROPS.LOADING]: false,
    [PROPS.ERROR]: null,
    [PROPS.LIST]: newList
  });
}

function removeUserGroupFromList (state, action) {
  const newList =  cloneDeep(state[PROPS.LIST]);
  newList.resources = newList.resources?.filter(group => group.id !== action.data.id);
  if (typeof newList.totalResults === 'number') {
    newList.totalResults--;
  }
  return Object.assign({}, state, {
    [PROPS.LOADING]: false,
    [PROPS.ERROR]: null,
    [PROPS.LIST]: newList
  });
}

function clearLoadingAndError (state) {
  return Object.assign({}, state, {
    [PROPS.LOADING]: false,
    [PROPS.ERROR]: null
  });
}