import AuthStorage from '../services/storage/AuthStorage';

// load auth from session storage
export default function PersistedState() {
  try {
    const authState = AuthStorage.get();

    if (authState === null) {
      return undefined;
    } else {
      return { auth: {
        isAuthenticated: true,
        ...authState
      }};
    }
  } catch (ignore) {
    return undefined;
  }
}
