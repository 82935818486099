import _ from 'lodash';

export default function parseError(error) {
  let msg = _.get(error, 'response.data.msg', 'Something went wrong');
  //msg could be a json string in the case of uaa server itself having error
  try {
    const uaaServerResponse = JSON.parse(msg);
    msg = uaaServerResponse.error_description;
  } catch (ignore) {
    //guess it wasn't a json string after all.
    //assume msg is actually an error message
  }
  return msg;
}