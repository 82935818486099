import { ACTIONS, INITIAL_STATE, SYSTEM_ERROR_TYPES } from './config.consts';

export default function configReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case ACTIONS.RESET: {
      return {
        ...INITIAL_STATE
      };
    }
    case ACTIONS.NETWORK_ERROR: {
      return {
        ...state,
        systemError: true,
        systemErrorType: SYSTEM_ERROR_TYPES.NETWORK
      };
    }
    case ACTIONS.UAA_CONNECTION_ERROR: {
      return {
        ...state,
        systemError: true,
        systemErrorType: SYSTEM_ERROR_TYPES.UAA_CONNECTION
      };
    }
    case ACTIONS.GENERIC_500_ERROR: {
      return {
        ...state,
        systemError: true,
        systemErrorType: SYSTEM_ERROR_TYPES.GENERIC_500
      };
    }
    case ACTIONS.FORBIDDEN_ERROR: {
      return {
        ...state,
        systemError: true,
        systemErrorType: SYSTEM_ERROR_TYPES.FORBIDDEN
      };
    }
    default:
      return state;
  }
}