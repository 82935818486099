import Axios from 'axios';
import path from 'path';

/**
 * PUTs to endpoint
 * @param { id, data } props
 * @description data is body
 */
export function updateApi({ id, data } = {}) {
  let url = path.join(this.endpointPrefix, this.endpoint, `${id}`);
  if (!id) {
    url = path.join(this.endpointPrefix, this.endpoint);
  }

  return Axios.request({
    url,
    method: 'PUT',
    data,
    baseURL: this.baseURL,
    ...this.requestProps
  });
}
