import { ACTIONS, INITIAL_STATE } from './users.consts';
export default function usersReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    ////////////////////////////////////////  SELECTION STATE  ////////////////////////////////////////
    case ACTIONS.SET_REFRESH_TABLE: {
      const { refreshTable } = action.payload;
      return {
        ...state,
        refreshTable
      }
    }
    case ACTIONS.USERS_REQUEST: {
      return {
        ...state,
        usersLoading: true,
        currentPage: []
      }
    }
    case ACTIONS.USERS_REQUEST_SUCCESS: {
      const { currentPage, totalUsers } = action.payload;
      return {
        ...state,
        usersLoading: false,
        currentPage,
        totalUsers
      }
    }
    case ACTIONS.SELECTION_CHANGE: {
      const { selectedRows } = action.payload;
      return {
        ...state,
        selectedRows
      };
    }
    case ACTIONS.CLEAR_SELECTION: {
      return {
        ...state,
        selectedRows: [],
        selectAllItems: false,
        refreshTable: true
      };
    }
    case ACTIONS.GROUPS_CHANGE: {
      const { groups } = action.payload;
      return {
        ...state,
        groups
      };
    }
    case ACTIONS.NEW_GROUPS_CHANGE: {
      const { newGroups } = action.payload;
      return {
        ...state,
        newGroups
      };
    }
    case ACTIONS.GROUPS_PAGE_REQUEST: {
      return {
        ...state,
        groupsLoading: true
      }
    }
    case ACTIONS.GROUPS_PAGE_REQUEST_SUCCESS: {
      const { groupsCurrentPage, totalGroups } = action.payload;
      return {
        ...state,
        groupsLoading: false,
        totalGroups,
        groupsCurrentPage
      }
    }
    case ACTIONS.SET_CURRENT_PAGE_SIZE: {
      const {pageSize} = action.payload;
      return {...state, pageSize};
    }
    case ACTIONS.SELECT_ALL_ITEMS: {
      const {selectAllItems} = action.payload;
      return {...state, selectAllItems};
    }
    ////////////////////////////////////////  MODAL OPEN STATE  ////////////////////////////////////////
    case ACTIONS.OPEN_BULK_ADD_USERS_TO_GROUP_MODAL: {
      return {
        ...state,
        bulkAddUsersToGroupModalOpen: true,
        groups: [],
        newGroups: [],
        groupsCurrentPage: [],
        groupsLoading: true
      };
    }
    case ACTIONS.CLOSE_BULK_ADD_USERS_TO_GROUP_MODAL: {
      return {
        ...state,
        bulkAddUsersToGroupModalOpen: false,
        groups: [],
        newGroups: [],
        groupsCurrentPage: [],
        groupsLoading: false
      };
    }
    case ACTIONS.OPEN_BULK_REMOVE_USERS_FROM_GROUP_MODAL: {
      return {
        ...state,
        bulkRemoveUsersFromGroupModalOpen: true,
        groups: [],
        groupsCurrentPage: [],
        groupsLoading: true
      };
    }
    case ACTIONS.CLOSE_BULK_REMOVE_USERS_FROM_GROUP_MODAL: {
      return {
        ...state,
        bulkRemoveUsersFromGroupModalOpen: false,
        groups: [],
        groupsCurrentPage: [],
        groupsLoading: false
      };
    }
    case ACTIONS.OPEN_BULK_DELETE_USERS_MODAL: {
      return {
        ...state,
        bulkDeleteUsersModalOpen: true
      }
    }
    case ACTIONS.CLOSE_BULK_DELETE_USERS_MODAL: {
      return {
        ...state,
        bulkDeleteUsersModalOpen: false
      };
    }
    case ACTIONS.OPEN_BULK_UNLOCK_ACCONTS_MODAL: {
      return {
        ...state,
        bulkUnlockAccountsModalOpen: true
      }
    }
    case ACTIONS.CLOSE_BULK_UNLOCK_ACCONTS_MODAL: {
      return {
        ...state,
        bulkUnlockAccountsModalOpen: false
      };
    }
    case ACTIONS.OPEN_CREATE_NEW_USER_MODAL: {
      return {
        ...state,
        createNewUserModalOpen: true,
        groups: [],
        newGroups: [],
        groupsCurrentPage: [],
        groupsLoading: true
      };
    }
    case ACTIONS.CLOSE_CREATE_NEW_USER_MODAL: {
      return {
        ...state,
        createNewUserModalOpen: false,
        groups: [],
        newGroups: [],
        groupsCurrentPage: [],
        groupsLoading: false
      };
    }
    case ACTIONS.OPEN_EDIT_USER_MODAL: {
      //copy user's groups to the selectedGroups
      const user = state.selectedRows[0];
      const groups = user.groups.map(g => g.display)
      return {
        ...state,
        editUserModalOpen: true,
        groups,
        newGroups: [],
        groupsCurrentPage: [],
        groupsLoading: true
      };
    }
    case ACTIONS.CLOSE_EDIT_USER_MODAL: {
      return {
        ...state,
        editUserModalOpen: false,
        groups: [],
        newGroups: [],
        groupsCurrentPage: [],
        groupsLoading: false
      };
    }
    ////////////////////////////////////////  MODAL REQUEST STATE  ////////////////////////////////////////

    case ACTIONS.EDIT_USER_REQUEST:
    case ACTIONS.CREATE_USER_REQUEST:
    case ACTIONS.BULK_UNLOCK_ACCOUNTS_REQUEST:
    case ACTIONS.BULK_DELETE_USERS_REQUEST:
    case ACTIONS.BULK_REMOVE_USERS_FROM_GROUP_REQUEST:
    case ACTIONS.BULK_ADD_USERS_TO_GROUP_REQUEST: {
      return {
        ...state,
        modalLoading: true,
        errorMessage: ''
      };
    }
    case ACTIONS.EDIT_USER_SUCCESS:
    case ACTIONS.CREATE_USER_SUCCESS:
    case ACTIONS.BULK_REQUEST_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        refreshTable: true,
        errorMessage: '',
        groupsLoading: false,
        groups: [],
        groupsCurrentPage: [],
        newGroups: [],
        bulkAddUsersToGroupModalOpen: false,
        bulkRemoveUsersFromGroupModalOpen: false,
        bulkDeleteUsersModalOpen: false,
        bulkUnlockAccountsModalOpen: false,
        createNewUserModalOpen: false,
        editUserModalOpen: false
      }
    }
    case ACTIONS.EDIT_USER_FAILURE:
    case ACTIONS.CREATE_USER_FAILURE:
    case ACTIONS.BULK_REQUEST_FAILURE: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        modalLoading: false,
        errorMessage
      }
    }
    default:
      return state;
  }
}