import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { EMS_GROUPS } from '../../App.consts';

export default function AddGroups(props) {
  //properties
  const {
    isLoading,
    value,
    defaultGroups = EMS_GROUPS,
    label = 'Groups',
    placeholder = 'Enter groups...',
    helperText
  } = props;

  //functions
  const {
    onChange
  } = props;

  return (
    <Autocomplete
      onChange={(event, values, reason) => {
        onChange(values);
      }}
      defaultValue={value}
      disabled={isLoading}
      multiple
      id='add-goups'
      options={defaultGroups}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant='outlined' label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant='filled' label={label} placeholder={placeholder}
          helperText={helperText} InputLabelProps={{color: 'secondary'}}
        />
      )}
    />
  );
}