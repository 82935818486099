
export const BULK_ACTIONS = [
  { key: 'ALL', label: 'All actions' },
  { key: 'ADD_USERS', label: 'Add Users' },
  { key: 'REMOVE_USERS', label: 'Remove Users' },
  { key: 'ADD_USERS_TO_GROUP', label: 'Add Users To Group' },
  { key: 'REMOVE_USERS_FROM_GROUP', label: 'Remove Users From Group' }
];

export const TABLE_COLUMNS = [
  {
    title: 'Username',
    field: 'username',
    defaultGroupOrder: 0
  },
  {
    title: 'Success',
    field: 'success',
    type: 'boolean'
  },
  {
    title: 'Message',
    field: 'msg'
  }
];

export const TABLE_OPTIONS = {
  exportButton: true,
  exportAllData: true,
  grouping: true,
  pageSize: 20,
  pageSizeOptions: [5, 10, 20, 50, 75, 100]
};

export const EXAMPLE_FILE_URL = '/assets/Sample-Template.xlsx';