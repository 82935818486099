import { ACTIONS } from './auth.consts';

export const loginRequest = ({ userId, userPassword }) => ({
  type: ACTIONS.LOGIN_REQUEST,
  payload: { userId, userPassword }
});

export const loginSuccess = ({ token, scope, expiresIn }) => ({
  type: ACTIONS.LOGIN_SUCCESS,
  payload: { token, scope, expiresIn }
});

export const loginFailure = ({ message }) => ({
  type: ACTIONS.LOGIN_FAILURE,
  payload: { message }
});

export const logout = () => ({
  type: ACTIONS.LOGOUT
})

export const persistAuth = ({ token, scope, expiresIn }) => ({
  type: ACTIONS.PERSIST_AUTH,
  payload: { token, scope, expiresIn }
});

export const clearAuth = () => ({
  type: ACTIONS.CLEAR_AUTH
});
