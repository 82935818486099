import { all, call, takeEvery, put } from 'redux-saga/effects';
import { bulkOperationApi } from '../../../services';

import { ACTIONS, API_ACTIONS } from '../import.consts';
import * as importActions from '../import.actions';

export function* allRequest(action) {
  const { formData, groups } = action.payload;
  const params = {
    action: API_ACTIONS.ALL,
    groups
  }
  const apiProps = {
    data: formData,
    params
  };

  try {
    const { data } = yield call(bulkOperationApi.create, apiProps);
    yield put(importActions.allSuccess({ results: data }));

  } catch (error) {
    const { response: { data } } = error;
    yield put(importActions.allFailure({ message: data.msg }));
  }
}

function* allRequetSaga() {
  yield takeEvery(ACTIONS.ALL_REQUEST, allRequest);
}

export default function* rootAddUsersSaga() {
  yield all([
    allRequetSaga()
  ]);
}