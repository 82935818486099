import React from 'react';
import { Form } from '@fh-components/form';
import Button from '@fh-components/button';

import './LoginForm.scss';

export default function LoginForm(props = {}) {

  const schema = [{
    type: 'input',
    label: 'Email',
    selector: 'email',
    inline: false,
    subType: 'text',
    props: {
      placeholder: 'Enter email...',
      variant: 'outlined',
      style: {width: '100%'} //override InputCommonStyles width
    },
    rules: {
      required: 'Email is required',
      pattern: {
        value: /^\S+@\S+$/,
        message: 'Please enter a valid email'
      }
    }
  }, {
    type: 'input',
    label: 'Password',
    selector: 'password',
    inline: false,
    subType: 'password',
    props: {
      placeholder: 'Enter password...',
      variant: 'outlined',
      style: {width: '100%'} //override InputCommonStyles width
    },
    rules: {
      required: 'Password is required'
    }
  }];

  const handleSubmit = (form) => {
    const { onSubmit } = props;
    onSubmit({ userId: form.email, userPassword: form.password })
  };

  const renderError = () => {
    let { errorMessage } = props;
    if (!errorMessage) {
      return null;
    } else {
      return (
        <div className='login-form-error'>
          {errorMessage}
        </div>
      );
    }
  };

  const renderFooter = () => {
    const { isLoading } = props;
    return (
      <div className='login-form-footer'>
        <Button
          loading={isLoading}
          color={Button.Colors.Primary}
          disabled={isLoading}>
          Log In
        </Button>
      </div>
    );
  };

  return(
    <div className='login-form-page'>
      <div className='login-form-container'>
        <div className='login-form'>
          <Form onSubmit={(e) => handleSubmit(e)} schema={schema}>
            {(props) => (
              <>
                <div className='login-form-header'>Login</div>
                <div className='login-form-message'>Welcome. Please log in.</div>
                <div className='login-form-body'>
                  {props.renderFormFields(schema)}
                </div>
                {renderError()}
                {renderFooter()}
              </>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}