import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { userApi } from '../../../services';
import parseError from '../../__helpers__/parseApiError';

import { ACTIONS } from '../users.consts';
import { selectGroups, selectNewGroups } from '../users.selectors';
import * as userActions from '../users.actions';

export function* createUserRequest(action) {
  const { userName, firstName, lastName, generatePassword, password } = action.payload;

  const { groups } = yield select(selectGroups);
  const { newGroups } = yield select(selectNewGroups);

  const apiPayload = {
    userName,
    firstName,
    lastName
  };
  if (!generatePassword) {
    apiPayload.generatePassword = generatePassword;
    apiPayload.password = password;
  }
  if (groups.length || newGroups.length) {
    apiPayload.groups = [...groups, ...newGroups];
  }

  try {
    yield call(userApi.create, { data: apiPayload });
    yield put(userActions.createUserRequestSuccess());
  } catch (error) {
    console.error(error);
    const errorMessage = parseError(error);
    yield put(userActions.createUserRequestFailure({ errorMessage }));
  }
}

function* createUserRequestSaga() {
  yield takeEvery(ACTIONS.CREATE_USER_REQUEST, createUserRequest);
}

export default function* rootCreateUserRequestSaga() {
  yield all([
    createUserRequestSaga()
  ]);
}

