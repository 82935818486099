import React from 'react';
import Button from '@fh-components/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import tableIcons from '../../TableIcons';
import Pluralize from 'react-pluralize';

import If from '../../If/If';
import ModalMessage from '../ModalMessage/ModalMessage';

import './BulkRemoveUsersFromGroupModal.scss';

export default function BulkRemoveUsersFromGroupModal(props) {
  // properties
  const {
    modalLoading,
    errorMessage,
    selectedRows,
    groups
  } = props;

  // functions
  const {
    bulkRemoveUsersFromGroupRequest,
    closeBulkRemoveUsersFromGroupModal,
    groupsChange
  } = props;

  const tableRef = React.createRef();
  const usersCount = selectedRows.length;
  const groupsCount = groups.length;

  // prepare table data
  const usersGroups = selectedRows.map(row => row.groupsFiltered.split(', '))
    .reduce((accum, curVal) => (accum.concat(curVal)), []);
  const uniqueUsersGroups = [...new Set(usersGroups)];
  uniqueUsersGroups.sort();
  const data = uniqueUsersGroups.map(groupName => ({
    groupName,
    tableData: { checked: groups.includes(groupName) }
  }));

  const [totalGroups, setTotalGroups] = React.useState(data.length);

  const handleSelectionChange = (selection) => {
    groupsChange({
      groups: selection.map(row => row.groupName)
    });
  };

  const updateTotal = () => {
    setTotalGroups(tableRef.current?.state?.data?.length);
  }

  return (
    <Dialog className='bulk-remove-users-from-group-modal' open={true} fullWidth={true} maxWidth='sm'>
      <DialogTitle>Bulk Remove <Pluralize singular='User' plural='Users' count={usersCount}/> from Groups</DialogTitle>
      <DialogContent>
        <div className='groups-list'>
          <MaterialTable
            icons={tableIcons} data={data} isLoading={modalLoading} tableRef={tableRef}
            title={`${totalGroups} Group${totalGroups !== 1 ? 's' : ''}`}
            onSelectionChange={handleSelectionChange} onSearchChange={updateTotal}
            onChangePage={updateTotal}
            columns={[
              {
                title: 'Group Name',
                field: 'groupName',
                type: 'string',
                defaultSort: 'asc'
              }
            ]}
            options={{
              selection: true,
              showTextRowsSelected: false,
              showSelectAllCheckbox: true,
              debounceInterval: 400,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 50, 75, 100]
            }}
          />
        </div>
        <If condition={!errorMessage}>
          <ModalMessage visible={groupsCount > 0}>
            Remove <strong><Pluralize singular='User' plural='Users' count={usersCount}/></strong>{' '}
            from <Pluralize singular='Group' plural='Groups' count={groupsCount}/>
          </ModalMessage>
        </If>
        <If condition={!!errorMessage}>
          <ModalMessage visible={true}>
            {errorMessage}
          </ModalMessage>
        </If>

      </DialogContent>

      <DialogActions>
        <Button
          color={Button.Colors.Secondary}
          onClick={closeBulkRemoveUsersFromGroupModal}
          loading={modalLoading}
          disabled={modalLoading}
          >
            Cancel
        </Button>
        <Button
          color={Button.Colors.Primary}
          onClick={bulkRemoveUsersFromGroupRequest}
          loading={modalLoading}
          disabled={groupsCount < 1 || modalLoading}
          >
          Remove from Groups
        </Button>
      </DialogActions>
    </Dialog>
  );
}