export const ACTIONS = {
  RESET: 'import/reset',
  ADD_USERS_REQUEST: 'import/add-users-request',
  ADD_USERS_SUCCESS: 'import/add-users-success',
  ADD_USERS_FAILURE: 'import/add-users-failure',
  REMOVE_USERS_REQUEST: 'import/remove-users-request',
  REMOVE_USERS_SUCCESS: 'import/remove-users-success',
  REMOVE_USERS_FAILURE: 'import/remove-users-failure',
  ADD_USERS_TO_GROUP_REQUEST: 'import/add-users-to-gorup-request',
  ADD_USERS_TO_GROUP_SUCCESS: 'import/add-users-to-gorup-success',
  ADD_USERS_TO_GROUP_FAILURE: 'import/add-users-to-gorup-failure',
  REMOVE_USERS_FROM_GROUP_REQUEST: 'import/remove-users-from-gorup-request',
  REMOVE_USERS_FROM_GROUP_SUCCESS: 'import/remove-users-from-gorup-success',
  REMOVE_USERS_FROM_GROUP_FAILURE: 'import/remove-users-from-gorup-failure',
  ALL_REQUEST: 'import/all-request',
  ALL_SUCCESS: 'import/all-success',
  ALL_FAILURE: 'import/all-failure'
}

export const API_ACTIONS = {
  ALL: 'ALL',
  ADD_USERS: 'ADD_USERS',
  REMOVE_USERS: 'REMOVE_USERS',
  ADD_USERS_TO_GROUP: 'ADD_USERS_TO_GROUP',
  REMOVE_USERS_FROM_GROUP: 'REMOVE_USERS_FROM_GROUP'
}

export const INITIAL_STATE = {
  isLoading: false,
  errorMessage: null,
  results: null,
  summary: {
    startTime: null,
    endTime: null,
    duration: null,
    total: null,
    successes: null,
    failures: null
  }
}