import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';

import tableIcons from '../TableIcons';
import UsersListToolbar from './UsersList.Toolbar';
import BulkAddUsersToGroupModal from './BulkAddUsersToGroupModal';
import BulkRemoveUsersFromGroupModal from './BulkRemoveUsersFromGroupModal';
import BulkDeleteUsersModal from './BulkDeleteUsersModal';
import BulkUnlockAccountsModal from './BulkUnlockAccountsModal';
import CreateNewUserModal from './CreateNewUserModal';
import Loader from '../Loader/Loader';
import If from '../If/If';
import EditUserModal from './EditUserModal';

import { TABLE_COLUMNS, TABLE_OPTIONS } from './UsersList.consts';
import datasource from './UsersList.datasource';

import './UsersList.scss';

export default function UsersList(props) {
  // Properties
  const {
    refreshTable,
    usersLoading,
    totalUsers,
    bulkAddUsersToGroupModalOpen,
    bulkRemoveUsersFromGroupModalOpen,
    bulkDeleteUsersModalOpen,
    bulkUnlockAccountsModalOpen,
    createNewUserModalOpen,
    EditUserModalOpen
  } = props;

  // Functions
  const {
    usersRequest,
    usersRequestSuccess,
    setRefreshTable,
    selectionChange,
    selectedRows
  } = props;
  const tableRef = React.createRef();

  const [tableOptions, setTableOptions] = React.useState(TABLE_OPTIONS);

  const datasourceContext = {
    usersRequest,
    usersRequestSuccess,
    selectedRows,
    selectionChange
  };

  const onChangeRowsPerPage = (pageSize) => {
    setTableOptions(options => {
      return {...options, pageSize};
    });
    const {setCurrentPageSize} = props;
    setCurrentPageSize({pageSize});
  }

  React.useEffect(() => {
    if (refreshTable) {
      tableRef.current && tableRef.current.onQueryChange();
      setRefreshTable({ refreshTable: false });
    }
  }, [refreshTable, tableRef, setRefreshTable]);

  return (
    <Container className='users-list'>
      <If condition={usersLoading}>
        <Loader />
      </If>
      <Grid container direction='column'>
        <Grid item className='toolbar-container'>
          <UsersListToolbar />
        </Grid>
        <Grid item className='table-container'>
          <MaterialTable
            title={`${totalUsers} User${totalUsers !== 1 ? 's' : ''}`}
            columns={TABLE_COLUMNS} options={tableOptions} data={datasource.bind(datasourceContext)}
            icons={tableIcons} tableRef={tableRef}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSelectionChange={selection => handleSelectionChange(selection, props)}
          />
        </Grid>
      </Grid>
      <If condition={bulkAddUsersToGroupModalOpen}>
        <BulkAddUsersToGroupModal />
      </If>
      <If condition={bulkRemoveUsersFromGroupModalOpen}>
        <BulkRemoveUsersFromGroupModal />
      </If>
      <If condition={bulkDeleteUsersModalOpen}>
        <BulkDeleteUsersModal />
      </If>
      <If condition={bulkUnlockAccountsModalOpen}>
        <BulkUnlockAccountsModal />
      </If>
      <If condition={createNewUserModalOpen}>
        <CreateNewUserModal />
      </If>
      <If condition={EditUserModalOpen}>
        <EditUserModal />
      </If>
    </Container>
  );
}

export const handleSelectionChange = (selection, { selectedRows, currentPage, selectionChange }) => {
  const selectionUsernames = selection.map(row => row.username);
  const allSelectedUsernames = selectedRows.map(row => row.username);
  const currentPageSelections = currentPage.filter(({ username }) => allSelectedUsernames.includes(username));
  const currentPageSelectionUsernames = currentPageSelections.map(row => row.username);
  const deletions = currentPageSelectionUsernames.filter(username => !selectionUsernames.includes(username));
  const additions = selection.filter(({ username }) => !currentPageSelectionUsernames.includes(username));

  const newSelectedRows = [...selectedRows, ...additions].filter(row => !deletions.includes(row.username));

  selectionChange({ selectedRows: newSelectedRows });
};