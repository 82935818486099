import { usersApi } from '../../services';
import { ASC_DESC, UAA_GROUPS } from '../../App.consts';

async function datasource (query) {
  // expect context to be bound to datasource
  const {
    usersRequest,
    selectedRows,
    usersRequestSuccess,
    selectionChange
  } = this;
  const selectedIds = selectedRows.map(row => row.id);


  const params = transformParams(query);
  usersRequest()
  const { data } = await usersApi.getAll({ params });
  const response = transformResponse(data, selectedIds);
  updateSelectedRows(response, selectedRows, selectionChange);
  usersRequestSuccess({
    currentPage: [...response.data],
    totalUsers: response.totalCount
  });

  return response;
}

export const transformParams = (query) => {
  const params = {};

  // Pagination
  params.startIndex = ((query.page) * query.pageSize) + 1;
  params.count = query.pageSize;

  // SortBy
  if (query.orderBy) {
    params.sortBy = query.orderBy.sortByField || query.orderBy.field;
    params.sortOrder = ASC_DESC[query.orderDirection];
  }

  //Search
  if (query.search) {
    // TODO I do not understand why these scim filters work
    // but name.familyName (as described in the docs) does not.
    const scimFilters = ['username', 'name:familyName', 'name:givenName'].map(
      col => `${col} co "${query.search}"`
    );
    params.filter = scimFilters.join(' or ');
  }
  return params;
}

export const transformResponse = (response, selectedIds) => {
  const { resources, startIndex, itemsPerPage, totalResults } = response;

  const data = resources.map(user => ({
    id: user.id,
    username: user.userName,
    first: user.name.givenName,
    last: user.name.familyName,
    active: user.active,
    // previousLogonTime: user.previousLogonTime ? new Date(user.previousLogonTime) : undefined,
    lastLogonTime: user.lastLogonTime ? new Date(user.lastLogonTime) : undefined,
    passwordLastModified: user.passwordLastModified,
    groupsFiltered:flattenAndFilterGroups(user.groups),
    groups: user.groups,
    tableData: { checked: selectedIds.includes(user.id)}
  }));

  const page = startIndex < itemsPerPage ? 0 : Math.floor(startIndex/itemsPerPage);
  return {
    data,
    page,
    totalCount: totalResults
  }
};

const SHOW_ALL_GROUPS = process.env.REACT_APP_SHOW_ALL_GROUPS === 'true';

export const flattenAndFilterGroups = SHOW_ALL_GROUPS ?
(groups) => groups.map(g => g.display).join(', ') :
(groups) => groups.map(g => g.display)
.filter((g) => !UAA_GROUPS.includes(g.toLowerCase()))
.join(', ');

export const updateSelectedRows = (response, selectedRows, selectionChange) => {
  const rowsMap = response.data.reduce((accum, curVal) => ({...accum, [curVal.id]: curVal}), {});

  // replace any selected rows with the new rows
  const updatedSelectedRows = selectedRows.map(row => rowsMap[row.id] ? rowsMap[row.id] : row);

  selectionChange({ selectedRows: updatedSelectedRows });
}

export default datasource;