import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import FhNavbar from '@fh-components/navbar';
import { ReactComponent as AppLogo } from './logo.svg';

import { ROUTES } from '../../App.consts';

import './NavBar.scss';
export default class NavBar extends Component {

  state = {
    anchorEl: null
  };

  handleMenu({ currentTarget }) {
    this.setState({ anchorEl: currentTarget });
  };

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleLogout() {
    const { logout } = this.props;
    logout();
    this.handleClose();
  };

  render() {
    return (
      <FhNavbar>
        <FhNavbar.Left>
          <FhNavbar.Brand>
            <AppLogo />
            <span>UAA Management</span>
          </FhNavbar.Brand>
          {this.renderLeftItems()}
        </FhNavbar.Left>
        {this.renderRightSide()}
      </FhNavbar>
    );
  }

  renderLeftItems() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      return (
        <>
          <FhNavbar.Separator />
          <FhNavbar.Item route={ROUTES.ROOT}>Users</FhNavbar.Item>
          <FhNavbar.Item route={`${ROUTES.ROOT}${ROUTES.USER_GROUPS}`}>Groups</FhNavbar.Item>
          <FhNavbar.Item route={`${ROUTES.ROOT}${ROUTES.IMPORT_UTILITY}`}>Import Utility</FhNavbar.Item>
        </>
      );
    } else {
      return null;
    }
  }

  renderRightSide() {
    const { isAuthenticated } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    if (isAuthenticated) {
      return (
        <div>
          <IconButton
            role='button'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={this.handleMenu.bind(this)}
            color='inherit'
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose.bind(this)}
          >
            <MenuItem onClick={this.handleLogout.bind(this)}>Logout</MenuItem>
          </Menu>
        </div>
      );
    } else {
      return null;
    }
  }
}
