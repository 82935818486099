import { connect } from 'react-redux';
import { groupsChange, groupsPageRequest, groupsPageRequestSuccess } from '../../../store/users/users.actions';
import UsersListGroupsList from './UsersList.GroupsList';

const mapStateToProps = (state) => ({
  groups: state.users.groups,
  totalGroups: state.users.totalGroups,
  groupsCurrentPage: state.users.groupsCurrentPage
});

const mapDispatchToProps = {
  groupsChange,
  groupsPageRequest,
  groupsPageRequestSuccess
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersListGroupsList);