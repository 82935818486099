import { deleteApi } from '../base.api/delete.base.api';
import { getAllApi } from '../base.api/get.all.base.api';
import { patchApi } from '../base.api/patch.base.api';
import { ENDPOINTS, BE_BASE_URL, UAA_API_PREFIX } from '../api.consts';

function usersApi() {
  const context = {
    endpoint: ENDPOINTS.USERS,
    baseURL: BE_BASE_URL,
    endpointPrefix: UAA_API_PREFIX
  };

  // only implement the methods we use
  return {
    // get: getApi.bind(context),
    getAll: getAllApi.bind(context),
    // create: createApi.bind(context),
    // update: updateApi.bind(context),
    delete: deleteApi.bind(context),
    patch: patchApi.bind(context)
  };
}

export default usersApi();