import React from 'react';
import Button from '@fh-components/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Pluralize from 'react-pluralize';

import If from '../../If/If';
import ModalMessage from '../ModalMessage/ModalMessage';

import './BulkUnlockAccountsModal.scss';

export default function BulkUnlockAccountsModal(props) {
  // properties
  const {
    modalLoading,
    errorMessage,
    selectedRows
  } = props;

  // functions
  const {
    bulkUnlockAccountsRequest,
    closeBulkUnlockAccountsModal
  } = props;

  const usersCount = selectedRows.length;

  return (
    <Dialog className='bulk-unlock-accounts-modal' open={true} fullWidth={true} maxWidth='sm'>
      <DialogTitle>Bulk Unlock <Pluralize singular='Account' plural='Accounts' count={usersCount}/></DialogTitle>
      <DialogContent>
        <If condition={!errorMessage}>
          <ModalMessage visible={true}>
            <>
              Accounts become locked after too many failed login attempts and will automatically unlock after 30 minutes.
              Use this action to unlock accounts immediately.
            </>
          </ModalMessage>
        </If>
        <If condition={!!errorMessage}>
          <ModalMessage visible={true}>
            {errorMessage}
          </ModalMessage>
        </If>

      </DialogContent>

      <DialogActions>
        <Button
          color={Button.Colors.Secondary}
          onClick={closeBulkUnlockAccountsModal}
          loading={modalLoading}
          disabled={modalLoading}
          >
            Cancel
        </Button>
        <Button
          color={Button.Colors.Primary}
          onClick={bulkUnlockAccountsRequest}
          loading={modalLoading}
          disabled={modalLoading}
          >
          Unlock Accounts
        </Button>
      </DialogActions>
    </Dialog>
  );
}