import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { bulkUsersApi } from '../../../services';

import { ACTIONS } from '../users.consts';
import { selectSelectedRows, selectGroups, selectNewGroups } from '../users.selectors';
import * as userActions from '../users.actions';

export function* bulkUpdateUsersRequest(action) {
  const { selectedRows } = yield select(selectSelectedRows);
  const { groups } = yield select(selectGroups);
  const { newGroups } = yield select(selectNewGroups);

  const apiPayload = {
    userIds: selectedRows.map(row => row.id)
  };
  const successActions = [put(userActions.bulkRequestSuccess())];

  switch (action.type) {
    case ACTIONS.BULK_ADD_USERS_TO_GROUP_REQUEST: {
      apiPayload.groupAdditions = [...groups, ...newGroups];
      break;
    }
    case ACTIONS.BULK_REMOVE_USERS_FROM_GROUP_REQUEST: {
      apiPayload.groupDeletions = [...groups];
      break;
    }
    case ACTIONS.BULK_DELETE_USERS_REQUEST: {
      apiPayload.delete = true;
      successActions.push(put(userActions.clearSelection()));
      break;
    }
    case ACTIONS.BULK_UNLOCK_ACCOUNTS_REQUEST: {
      apiPayload.unlockAccount = true;
      break;
    }
    default:
      break;
  }
  try {
    yield call(bulkUsersApi.update, { data: apiPayload });
    yield all(successActions);
  } catch (error) {
    console.error(error);
    const errorMessage = parseError(error);
    yield put(userActions.bulkRequestFailure({ errorMessage }));
  }
}

function* bulkUpdateUsersRequestSaga() {
  yield all([
    takeEvery(ACTIONS.BULK_ADD_USERS_TO_GROUP_REQUEST, bulkUpdateUsersRequest),
    takeEvery(ACTIONS.BULK_REMOVE_USERS_FROM_GROUP_REQUEST, bulkUpdateUsersRequest),
    takeEvery(ACTIONS.BULK_DELETE_USERS_REQUEST, bulkUpdateUsersRequest),
    takeEvery(ACTIONS.BULK_UNLOCK_ACCOUNTS_REQUEST, bulkUpdateUsersRequest)
  ]);
}

export default function* rootBulkUpdateUsersRequestSaga() {
  yield all([
    bulkUpdateUsersRequestSaga()
  ]);
}

export function parseError(error) {
  // try to parse error, otherwise show generic default
  let errorMessage = 'Something went wrong';
  try {
    const { response: { data: { msg }}} = error;
    const parsedMsg = JSON.parse(msg);
    errorMessage = parsedMsg.error_description;
  } catch (ignore) {
    //ignore
  }
  return errorMessage;
}