import React, { useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import If from '../If/If';
import Loader from '../Loader/Loader';
import CreateUserGroupModal from './CreateUserGroupModal';
import DeleteUserGroupModal from './DeleteUserGroupModal';
import BulkDeleteUserGroupModal from './BulkDeleteUserGroupModal';
import { COLUMNS, initialState } from './UserGroupsList.consts';

import './UserGroupsList.scss';

export default function UserGroupList({
  // data
  loading,
  error,
  userGroupList,

  // methods
  requestUserGroupList,
  deleteUserGroupRequest,
  createUserGroupRequest,
  clearLoadingAndError
}) {

  const  [itemsPerPage, setItemsPerPage] = React.useState(initialState.itemsPerPage);
  const  [pageIndex, setPageIndex] = React.useState(initialState.pageIndex);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [createUserGroupModal, setCreateUserGroupModal] = React.useState(false);
  const [deleteUserGroupModal, setDeleteUserGroupModal] = React.useState(false);
  const [bulkDeleteUserGroupModal, setBulkDeleteUserGroupModal] = React.useState(false);
  const totalResults = userGroupList?.totalResults ||  0;

  useEffect(() => {
    let startIndex = (pageIndex * itemsPerPage) + 1;
    if (startIndex > 1 && startIndex > totalResults) {
      setPageIndex(pageIndex - 1);
      return;
    }
    requestUserGroupList({ startIndex: startIndex, count: itemsPerPage });
    // eslint-disable-next-line
  },[pageIndex, itemsPerPage, totalResults]);

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(event.target.value);
  };

  const selectAllHandler = (rows) => {
    const newSelected = selectedGroups.length === rows.length ? [] : rows;
    setSelectedGroups(newSelected);
  };

  const onCheckboxValueChange = (row) => {
    const group = selectedGroups.find(group => group.id === row.id);
    let newSelected;
    if (group) {
      newSelected = selectedGroups.filter(group => group.id !== row.id);
    } else {
      newSelected = [row].concat(selectedGroups);
    }
    setSelectedGroups(newSelected);
  };

  const tableRef = useRef();
  const rows = userGroupList?.resources || [];

  return (
    <Container className='usergroup-list-container' maxWidth='xl'>
      <Grid container direction='column' spacing={2}>
        {/* <Grid item className="grid-item">
          <Paper className='item-paper'>
            <Button
              color={Button.Colors.Primary}
              icon={AddIcon}
              onClick={() => setCreateUserGroupModal(false)}
              title="Create new user group"
            />
          </Paper>
        </Grid> */}
        <Grid item className="grid-item">
          <Paper className='item-paper'>
            <TableContainer className='table-container' ref={tableRef}>
              <Table size="small">
                <TableHead className="table-header">
                  <TableRow>
                    {/* <TableCell className="header-cell">
                      <Checkbox
                        checked={selectedGroups.length === rows.length}
                        onChange={selectAllHandler.bind(this, rows)}
                      />
                    </TableCell> */}
                    {COLUMNS.map((column) => (
                      <TableCell key={column.id} className="header-cell">
                        <b>{column.header}</b>
                      </TableCell>
                    ))}
                    <TableCell> <b>Actions</b> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='table-body'>
                  <If condition={loading}>
                    <Loader />
                  </If>
                  <If condition={error || false}>
                    <Typography className='error-container'>{error}</Typography>
                  </If>
                  <If condition={!loading && !error && rows.length === 0}>
                    <Typography>No results found</Typography>
                  </If>
                  {
                    rows.map((row) => {
                      return (
                        <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                          {/* <TableCell>
                            <Checkbox
                              checked={selectedGroups.some(group => group.id === row.id)}
                              onChange={onCheckboxValueChange.bind(this, row)}
                            />
                          </TableCell> */}
                          {COLUMNS.map((column) => {
                            const value = column.type === 'count' ? row[column.accessor].length : row[column.accessor];
                            return (
                              <TableCell key={column.id} style={column.style}>
                                {value}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <ClearIcon className="icon-button" onClick={(e) => {
                              setSelectedRow(row);
                              setDeleteUserGroupModal(true);
                            }}/>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={initialState.rowsPerPageOptions}
              component="div"
              count={totalResults}
              rowsPerPage={itemsPerPage}
              page={pageIndex}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <If condition={bulkDeleteUserGroupModal}>
        <BulkDeleteUserGroupModal
          loading={loading}
          error={error}
          setBulkDeleteUserGroupModal={setBulkDeleteUserGroupModal}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          clearLoadingAndError={clearLoadingAndError}
        />
      </If>
      <If condition={deleteUserGroupModal && selectedRow !== null}>
        <DeleteUserGroupModal
          loading={loading}
          error={error}
          rows = {rows}
          selectedRow={selectedRow}
          setDeleteUserGroupModal={setDeleteUserGroupModal}
          setSelectedRow={setSelectedRow}
          deleteUserGroupRequest={deleteUserGroupRequest}
          clearLoadingAndError={clearLoadingAndError}
        />
      </If>
      <If condition={createUserGroupModal}>
        <CreateUserGroupModal
          loading={loading}
          error={error}
          rows = {rows}
          setCreateUserGroupModal={setCreateUserGroupModal}
          createUserGroupRequest={createUserGroupRequest}
          clearLoadingAndError={clearLoadingAndError}
        />
      </If>
    </Container>
  );
}