import { connect } from 'react-redux';
import {
  allRequest,
  reset,
} from "../../store/import/import.actions";

import ImportUtility from './ImportUtility';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
  const { isLoading, errorMessage, results, summary } = state.import;
  return { isLoading, errorMessage, results, summary };
}

const mapDispatchToProps = {
  allRequest,
  reset
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportUtility));