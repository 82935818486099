import { ACTIONS } from './userGroups.consts';

export const requestUserGroupList = (params) => {
  return {
    type: ACTIONS.USER_GROUPS_REQUEST,
    params
  };
}

export const requestUserGroupListLoading = () => {
  return {
    type: ACTIONS.USER_GROUPS_REQUEST_LOADING
  };
}

export const requestUserGroupListSuccess = (data) => {
  return {
    type: ACTIONS.USER_GROUPS_REQUEST_SUCCESS,
    data
  };
}

export const requestUserGroupListFailure = (error) => {
  return {
    type: ACTIONS.USER_GROUPS_REQUEST_FAILURE,
    error
  };
}

export const createUserGroupRequest = (data) => {
  return {
    type: ACTIONS.CREATE_USER_GROUP_REQUEST,
    data
  };
}

export const createUserGroupLoading = () => {
  return {
    type: ACTIONS.CREATE_USER_GROUP_LOADING
  };
}

export const createUserGroupSuccess = (data) => {
  return {
    type: ACTIONS.CREATE_USER_GROUP_SUCCESS,
    data
  };
}

export const createUserGroupFailure = (error) => {
  return {
    type: ACTIONS.CREATE_USER_GROUP_FAILURE,
    error
  };
}

export const deleteUserGroupRequest = (data) => {
  return {
    type: ACTIONS.DELETE_USER_GROUP_REQUEST,
    data
  };
}

export const deleteUserGroupLoading = () => {
  return {
    type: ACTIONS.DELETE_USER_GROUP_LOADING
  };
}

export const deleteUserGroupSuccess = (data) => {
  return {
    type: ACTIONS.DELETE_USER_GROUP_SUCCESS,
    data
  };
}

export const deleteUserGroupFailure = (error) => {
  return {
    type: ACTIONS.DELETE_USER_GROUP_FAILURE,
    error
  };
}

export const clearLoadingAndError = () => {
  return {
    type: ACTIONS.CLEAR_LOADING_ERROR
  };
}