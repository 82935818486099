import { getAllApi } from '../base.api/get.all.base.api';
import { ENDPOINTS, BE_BASE_URL, BE_API_PREFIX } from '../api.consts';

function configApi() {
  const context = {
    endpoint: ENDPOINTS.CONFIG,
    baseURL: BE_BASE_URL,
    endpointPrefix: BE_API_PREFIX
  };

  // only implement the methods we use
  return {
    // get: getApi.bind(context),
    getAll: getAllApi.bind(context),
    // create: createApi.bind(context),
    // update: updateApi.bind(context),
    // delete: deleteApi.bind(context),
    // patch: patchApi.bind(context)
  };
}

export default configApi();