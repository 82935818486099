import { usersApi } from '../../../services';

export const usernameIsUnique = async username => {
  const params = {
    filter: `username eq "${username}"`
  }
  const { data: { resources } } = await usersApi.getAll({ params });
  if (resources.length === 0) {
    return true;
  } else {
    return 'This username already exists. Create a unique username.';
  }
};