export const initialState = {
  itemsPerPage: 10,
  pageIndex: 0,
  rowsPerPageOptions: [5, 10, 20, 50, 75, 100]
};

export const COLUMNS = [{
    id: 'displayName',
    header: 'Group Name',
    accessor: 'displayName',
    defaultSearchBy: true,
    style: {
      minWidth: '120px'
    }
  }, {
    id: 'memberCount',
    header: 'Members',
    accessor: 'members',
    type: 'count'
  }, {
    id: 'description',
    header: 'Description',
    accessor: 'description'
  }
];