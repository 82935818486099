import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import AppRoutes from './App.routes';
import store from './store/store';
import { Theme_Options } from './App.theme';

import './services/api/api.interceptor';


export default function App() {
  const theme = createMuiTheme(Theme_Options);
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <HashRouter type='slash'>
          <AppRoutes />
        </HashRouter>
      </Provider>
    </ThemeProvider>
  );
}
