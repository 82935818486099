export const TABLE_COLUMNS = [
  {
    title: 'Username',
    field: 'username'
  },
  {
    title: 'First',
    field: 'first',
    sortByField: 'givenName'
  },
  {
    title: 'Last',
    field: 'last',
    sortByField: 'familyName'
  },
  // {
  //   title: 'Previous Logon Time',
  //   field: 'previousLogonTime',
  //   type: 'datetime'
  // },
  {
    title: 'Last Login',
    field: 'lastLogonTime',
    type: 'datetime',
    sorting: false
  },
  {
    title: 'Password Last Modified',
    field: 'passwordLastModified',
    type: 'datetime',
    sorting: false
  },
  {
    title: 'Active',
    field: 'active',
    type: 'boolean'
  },
  {
    title: 'Groups',
    field: 'groupsFiltered',
    sorting: false
  }
];

export const TABLE_OPTIONS = {
  selection: true,
  showTextRowsSelected: false,
  showSelectAllCheckbox: true,
  debounceInterval: 400,
  columnsButton: true,
  pageSize: 20,
  pageSizeOptions: [5, 10, 20, 50, 75, 100]
};