import { getAllApi } from '../base.api/get.all.base.api';
import { createApi } from '../base.api/create.base.api';
// import { updateApi } from '../base.api/update.base.api';
import { deleteApi } from '../base.api/delete.base.api';
import { ENDPOINTS, BE_BASE_URL, UAA_API_PREFIX, BE_API_PREFIX } from '../api.consts';

function groupsApi() {
  const context = {
    endpoint: ENDPOINTS.GROUPS,
    baseURL: BE_BASE_URL,
    endpointPrefix: UAA_API_PREFIX
  };

  // only implement the methods we use
  return {
    // get: getApi.bind(context),
    getAll: getAllApi.bind(context),
    create: createApi.bind(context),
    // update: updateApi.bind(context),
    delete: deleteApi.bind(Object.assign({}, context, {
      endpoint: 'groups',
      endpointPrefix: BE_API_PREFIX
    })),
    // patch: patchApi.bind(context)
  };
}

export default groupsApi();