import React from 'react';
import Button from '@fh-components/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import tableIcons from '../../TableIcons';
import Pluralize from 'react-pluralize';

import If from '../../If/If';
import ModalMessage from '../ModalMessage/ModalMessage';

import './BulkDeleteUsersModal.scss';

export default function BulkDeleteUsersModal(props) {
  // properties
  const {
    modalLoading,
    errorMessage,
    selectedRows
  } = props;

  // functions
  const {
    bulkDeleteUsersRequest,
    closeBulkDeleteUsersModal
  } = props;

  const usersCount = selectedRows.length;

  // prepare table data
  const data = selectedRows.map(({ username }) => ({ username }));

  return (
    <Dialog className='bulk-delete-users-modal' open={true} fullWidth={true} maxWidth='sm'>
      <DialogTitle>Bulk Delete <Pluralize singular='User' plural='Users' count={usersCount}/></DialogTitle>
      <DialogContent>
        <div className='users-list'>
          <MaterialTable
            icons={tableIcons} data={data} isLoading={modalLoading}
            title={`${usersCount} User${usersCount !== 1 ? 's' : ''}`}
            columns={[
              {
                title: 'Username',
                field: 'username',
                type: 'string',
                defaultSort: 'asc'
              }
            ]}
            options={{
              debounceInterval: 400,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 50, 75, 100]
            }}
          />
        </div>
        <ModalMessage visible={true}>
          <If condition={!errorMessage}>
            The <strong><Pluralize singular='User' plural='Users' count={usersCount}/></strong>{' '}
            in the above list will be <strong>permanently</strong> deleted from the system.
          </If>
          <If condition={!!errorMessage}>
            {errorMessage}
          </If>
        </ModalMessage>
      </DialogContent>

      <DialogActions>
        <Button
          color={Button.Colors.Secondary}
          onClick={closeBulkDeleteUsersModal}
          loading={modalLoading}
          disabled={modalLoading}
          >
            Cancel
        </Button>
        <Button
          color={Button.Colors.Destructive}
          onClick={bulkDeleteUsersRequest}
          loading={modalLoading}
          disabled={modalLoading}
          >
          Delete&nbsp;<Pluralize singular='User' plural='Users' count={usersCount}/>&nbsp;Permanently
        </Button>
      </DialogActions>
    </Dialog>
  );
}