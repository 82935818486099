import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import './Loader.scss';

export default class Loader extends Component {
  state = {
    open : false
  };

  componentDidMount() {
    this.setState({open: true});
  }

  render() {
    const {open} = this.state;
    return (
      <Backdrop open={open}>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Grid item>
            <CircularProgress color='secondary' />
          </Grid>
          <Grid item>
            <Typography className='loader-hint' variant='body1' color='secondary'>
              Please be patient, this process may take a while.
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
    );
  }
}
