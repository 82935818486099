import Axios from 'axios';
import store from '../../store/store';
import { logout } from '../../store/auth/auth.actions';
import { networkError, uaaConnectionError, forbiddenError } from '../../store/config/config.actions';
import {
  HTTP_UNAUTHORIZED,
  HTTP_FORBIDDEN,
  HTTP_INTERNAL_ERROR,
  UAA_INVALID_TOKEN,
  UAA_TOKEN_EXPIRED_DESCRIPTION,
  UAA_CONNECTION_ERROR_SUBSTR,
  NETWORK_ERROR_MESSAGE,
  ERROR_TYPES
} from './api.consts';

export function request(config) {
  // access store
  const state = store.getState();
  const token = state.auth.token;

  // set token
  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

export function success(response) {
  return response;
}

export async function error(error) {
  const { config } = error;
  //ignore logout errors
  if (config.url.includes('logout')) {
    return Promise.resolve(error);
  }

  const errorType = getErrorType(error);
  if (errorType === ERROR_TYPES.TOKEN_EPIRED) {
    store.dispatch(logout());
  } else if (errorType === ERROR_TYPES.NETWORK_ERROR) {
    store.dispatch(networkError());
  } else if (errorType === ERROR_TYPES.UAA_CONNECTION) {
    store.dispatch(uaaConnectionError());
  } else if (errorType === ERROR_TYPES.FORBIDDEN) {
    store.dispatch(forbiddenError());
  }
  return Promise.reject(error);
}

export function isTokenExpiredResponse({ status, data }) {
  let tokenExpired = false;
  if (status === HTTP_UNAUTHORIZED) {
    // if from backend, will have json string in msg property
    try {
      const parsedMsg = JSON.parse(data.msg);
      if (parsedMsg.error === UAA_INVALID_TOKEN) {
        tokenExpired = true;
      }
    } catch (error) {
      //ignore - guess it wasnt a json string
    }
    // if from uaa, will have a error_description property
    if (data?.error_description === UAA_TOKEN_EXPIRED_DESCRIPTION) {
      tokenExpired = true;
    }
  }

  return tokenExpired;
}

export function isUAAConnectionError({ status, data }) {
  let uaaConnectionError = false;
  if(status === HTTP_INTERNAL_ERROR) {
    if(data?.msg.includes(UAA_CONNECTION_ERROR_SUBSTR)) {
      uaaConnectionError = true;
    }
  }
  return uaaConnectionError;
}

export function getErrorType(error) {
  const { response } = error;
  const { message } = error.toJSON();
  if (message === NETWORK_ERROR_MESSAGE) {
    return ERROR_TYPES.NETWORK_ERROR;
  }
  if (response) {
    if (isTokenExpiredResponse(response)) {
      return ERROR_TYPES.TOKEN_EPIRED;
    } else if (isUAAConnectionError(response)) {
      return ERROR_TYPES.UAA_CONNECTION;
    } if (response.status === HTTP_FORBIDDEN) {
      return ERROR_TYPES.FORBIDDEN;
    } else {
      return ERROR_TYPES.RECOVERABLE_ERROR;
    }
  }
}

Axios.interceptors.request.use(
  request
);

Axios.interceptors.response.use(
  success,
  error
);
