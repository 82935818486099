
export const SCHEMA = [{
  type: 'input',
  label: 'Username',
  selector: 'userName',
  uaaPayloadSelected: 'username', //key in uaa user payload
  name: 'userName',
  inline: false,
  disabled: true,
  subType: 'text',
  props: {
    placeholder: 'Enter a valid email address...',
    variant: 'outlined',
    style: {width: '100%'} //override InputCommonStyles width
  },
  rules: {
    required: 'Username is required',
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, //same regex as used by backend
      message: 'Please enter a valid email'
    }
  }
}, {
  type: 'input',
  label: 'First Name',
  name: 'firstName',
  selector: 'firstName',
  uaaPayloadSelected: 'first', //key in uaa user payload
  inline: false,
  subType: 'text',
  props: {
    placeholder: 'Enter first name...',
    variant: 'outlined',
    autoComplete: 'off',
    style: {width: '100%'} //override InputCommonStyles width
  },
  rules: {
    required: 'First name is required',
    maxLength: {
      value: 255,
      message: 'Cannot exceed 255 characters'
    }
  }
}, {
  type: 'input',
  label: 'Last Name',
  name: 'lastName',
  uaaPayloadSelected: 'last', //key in uaa user payload
  selector: 'lastName',
  inline: false,
  subType: 'text',
  props: {
    placeholder: 'Enter last name...',
    variant: 'outlined',
    autoComplete: 'off',
    style: {width: '100%'} //override InputCommonStyles width
  },
  rules: {
    required: 'Last name is required',
    maxLength: {
      value: 255,
      message: 'Cannot exceed 255 characters'
    }
  }
}];