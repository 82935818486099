import React from 'react';
import Button from '@fh-components/button';
import { Form } from '@fh-components/form';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pluralize from 'react-pluralize';

import If from '../../If/If';
import ModalMessage from '../ModalMessage/ModalMessage';
import UsersListGroupsList from  '../UsersList.GroupsList';
import AddGroups from '../../AddGroups/AddGroups';
import { SCHEMA } from './EditUserModal.consts';

import './EditUserModal.scss';

function EditUserModal(props) {
  const [form, setForm] = React.useState();
  //properties
  const {
    modalLoading,
    errorMessage,
    selectedRows,
    groups,
    groupsLoading,
    newGroups
  } = props;

  const schema = _.cloneDeep(SCHEMA);
  const user = selectedRows[0];
  const usersGroups = user.groups.map(group => group.display);
  const groupAdditions = [
    ...newGroups,
    ...groups.filter(groupName => !usersGroups.includes(groupName))
  ];
  const groupDeletions = usersGroups.filter(groupName => !groups.includes(groupName));

  //functions
  const {
    closeEditUserModal,
    newGroupsChange,
    editUserRequest
  } = props;

  const handleAddGroups = names => {
    const deletions = newGroups.filter(name => !names.includes(name));
    const additions = names.filter(name => !newGroups.includes(name));

    if (deletions.length) {
      newGroupsChange({ newGroups: newGroups.filter(name => !deletions.includes(name)) });
    } else {
      newGroupsChange({ newGroups: [...newGroups, ...additions] });
    }
  };

  const handleSubmit = () => {
    const values = form.getValues();
    const { userName, firstName, lastName } = values;
    const { id } = user;
    editUserRequest({ id, userName, firstName, lastName, groupAdditions, groupDeletions });
  };

  //disable form fields if loading
  if (modalLoading) {
    schema.forEach(formfield => formfield.disabled = true);
  }

  //populate form fields with row data
  const defaultValues = schema
    .map(formField => ({ [formField['name']]: user[formField.uaaPayloadSelected] }))
    .reduce((values, value) => ({...values, ...value}), {});

  const renderSummaryMessage = () => {
    return (
      <ModalMessage visible={true}>
        <Pluralize singular='Group Addition' plural='Group Additions' count={groupAdditions.length} />
        {' '}/{' '}
        <Pluralize singular='Group Deletion' plural='Group Deletions' count={groupDeletions.length} />
      </ModalMessage>
    );
  };

  return (
    <Dialog className='edit-user-modal' open={true} fullWidth={true} maxWidth='sm'>
      <Form schema={schema} options={{ defaultValues }} disabled={modalLoading} onInit={({ form }) => setForm(form)}
        onSubmit={(values) => handleSubmit(values)}>
        {(props) => (
          <>
            <DialogTitle>
              Edit A User
            </DialogTitle>
            <DialogContent>
              <div className='form-fields'>
                {props.renderFormFields(schema)}
              </div>
              <div className={`groupsList-container ${groupsLoading ? 'loading' : ''}`}>
                <div className='loader'>
                  <CircularProgress color='secondary' />
                </div>
                <UsersListGroupsList isLoading={modalLoading} />
              </div>
              <div className='addGroups-container'>
                <AddGroups
                  label='Add new groups'
                  placeholder='Enter group name...'
                  helperText='New groups will be created upon submission'
                  isLoading={modalLoading}
                  onChange={names => handleAddGroups(names)}
                />
              </div>
            </DialogContent>

            <DialogActions>
              <If condition={!errorMessage}>
                {renderSummaryMessage()}
              </If>
              <If condition={!!errorMessage}>
                <ModalMessage visible={true}>
                  {errorMessage}
                </ModalMessage>
              </If>
              <Button
                color={Button.Colors.Primary}
                type='submit'
                loading={modalLoading}
                disabled={modalLoading}
                >
                  Save Changes
              </Button>
              <Button
                color={Button.Colors.Secondary}
                onClick={closeEditUserModal}
                loading={modalLoading}
                disabled={modalLoading}
                >
                  Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
}

export default EditUserModal;