import React from 'react';
import { Redirect } from 'react-router-dom';

import LoginForm from './LoginForm/LoginForm';

import { ROUTES } from '../../App.consts';

export default function Login(props) {
  const { isAuthenticated, loginRequest, errorMessage, isLoading } = props;

  function handleSubmit({ userId, userPassword }) {
    loginRequest({ userId, userPassword });
  }

  const formProps = { isLoading, errorMessage, onSubmit: handleSubmit.bind(this) };
  const page = isAuthenticated ? <Redirect to={ROUTES.ROOT} /> : <LoginForm {...formProps} />;

  return (
    <>
      { page }
    </>
  );
}
