export const TABLE_COLUMNS = [
  {
    title: 'Group Name',
    field: 'displayName',
    type: 'string',
    sorting: true,
    defaultSort: 'asc'
  }
];

export const TABLE_OPTIONS = {
  selection: true,
  showTextRowsSelected: false,
  showSelectAllCheckbox: true,
  debounceInterval: 400,
  pageSize: 10,
  pageSizeOptions: [5, 10, 20, 50, 75, 100]
};

