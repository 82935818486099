export const ROUTES = {
  ROOT: '/',
  LOGIN: 'login',
  IMPORT_UTILITY: 'import-utility',
  USER_GROUPS: 'user-groups'
};

export const FORM_VALIDATION = {
  MAX_INPUT_LENGTH: 255
};

export const UAA_GROUPS = [
  "openid",
  "password.write",
  "uaa.user",
  "approvals.me",
  "profile",
  "roles",
  "user_attributes",
  "uaa.offline_token",
  "scim.me",
  "cloud_controller.write",
  "cloud_controller_service_permissions.read",
  "oauth.approvals",
  "scim.userids",
  "uaa.resource",
  // "uaa.admin",
  "cloud_controller.read",
  "zones.write",
  "cloud_controller.admin",
  "clients.read",
  "clients.secret",
  "scim.invite",
  "clients.admin",
  "scim.read",
  "clients.write",
  "scim.write",
  "organizations.acme"
];

export const EMS_GROUPS = [
  'Approver',
  'Requestor',
  'Calendar_Admin',
  'Calendar_Super_Admin',
  'ems_hs_school.dashboard.user',
  'ems_hs_school.dashboard.operator',
  'ems_hs_district.dashboard.user',
  'ems_hs_district.dashboard.operator'
];

export const ASC_DESC = {
  'asc': 'ascending',
  'desc': 'descending'
};

export const FIELD_TO_SORTBY = {
  username: 'username',
  first: 'givenName',
  last: 'familyName',
  groupName: 'displayName'
};