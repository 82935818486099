import scssConsts from './styles/consts.scss';


// TODO fix testing environment
// during testing, imports from @fh-components/styles are undefined
// causing primary.main to be undefined
// which in turn causes an error when creating theme
// temporary fix is to omit theme when testing...
export const Theme_Options = scssConsts.ebonyclay === undefined ? {} :
{
  palette: {
    type: 'dark',
    background: {
      default: scssConsts.ebonyclayDark,
      paper: scssConsts.ebonyclay
    },
    action: {
      hover: scssConsts.amber,
      active: scssConsts.bunker,
      selected: scssConsts.active
    },
    primary: {
      light: scssConsts.amber,
      main: scssConsts.ebonyclay,
      dark: scssConsts.ebonyclayDark,
      contrastText: '#fff'
    },
    secondary: {
      main: scssConsts.amber
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        background: scssConsts.riverbed
      }
    }
  }
};
