import React, { useEffect, useRef } from "react";
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@fh-components/button';
import If from '../If/If';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function DeleteUserGroupModal ({
  loading,
  error,
  rows,
  selectedRow={},
  setDeleteUserGroupModal,
  setSelectedRow,
  deleteUserGroupRequest,
  clearLoadingAndError
}) {

  const prevRows = usePrevious(rows);

  const handleClose = () => {
    setSelectedRow(null);
    setDeleteUserGroupModal(false);
    clearLoadingAndError();
  };

  const handleDelete = () => {
    deleteUserGroupRequest(selectedRow);
  }

  useEffect(() => {
    if (prevRows && rows.length === prevRows.length - 1) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [rows]);

  return (
    <Dialog open={true} onClose={handleClose} disableBackdropClick>
      <DialogTitle> Delete group "{selectedRow?.displayName}" ?</DialogTitle>
      <DialogContent>
        <If condition={error}>
          <DialogContentText>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </DialogContentText>
        </If>
        <DialogContentText>
          The {selectedRow?.members?.length} existing member{selectedRow?.members?.length > 1 ? 's': ''}  of this group will be released. This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} loading={loading} disabled={loading}> Cancel </Button>
        <Button onClick={handleDelete} color="destructive" autoFocus loading={loading} disabled={loading}> Delete Group </Button>
      </DialogActions>
    </Dialog>
  );
}