import Axios from 'axios';
import path from 'path';

/**
 * DELETEs to endpoint
 * @param { id } props
 * @description id to delete
 */
export function deleteApi({ id } = {},  data) {
  let url = path.join(this.endpointPrefix, this.endpoint, `${id}`);

  return Axios.request({
    url,
    data,
    method: 'DELETE',
    baseURL: this.baseURL,
    ...this.requestProps
  });
}
