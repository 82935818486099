import { SYSTEM_ERROR_TYPES } from '../../store/config/config.consts';
import serverDown from '../../../images/undraw_server_down_s4lk.svg';
import genericError from '../../../images/undraw_feeling_blue_4b7q.svg';
import insufficientPermissions from '../../../images/undraw_security_o890.svg';

export const IMAGES = {
  DISCONNECTED: serverDown,
  SERVER_ERROR: genericError,
  FORBIDDEN: insufficientPermissions
};

export const ERROR_TITLES = {
  [SYSTEM_ERROR_TYPES.NETWORK]: 'Network Error',
  [SYSTEM_ERROR_TYPES.UAA_CONNECTION]: 'UAA Server Connection Issue',
  [SYSTEM_ERROR_TYPES.FORBIDDEN]: 'Insufficient Permissions',
  [SYSTEM_ERROR_TYPES.GENERIC_500]: 'Server Error'
};
export const DEFAULT_ERROR_TITLE = ERROR_TITLES[SYSTEM_ERROR_TYPES.GENERIC_500];

export const ERROR_MESSAGES = {
  [SYSTEM_ERROR_TYPES.NETWORK]: `Unable to connect. Check your connection status, and ensure the backend server is running`,
  [SYSTEM_ERROR_TYPES.UAA_CONNECTION]: `The backend server appears unable to connect to the UAA server.
    Please check the UAA server is running and accessible by the backend server.`,
  [SYSTEM_ERROR_TYPES.FORBIDDEN]: `Your account does not have the permissions required to use this application.
    Please logout and log back in with a different account.`,
  [SYSTEM_ERROR_TYPES.GENERIC_500]: 'Something went wrong with the server. Check console for more information.'
};
export const DEFAULT_ERROR_MESSAGE = ERROR_MESSAGES[SYSTEM_ERROR_TYPES.GENERIC_500];

export const ERROR_IMAGES = {
  [SYSTEM_ERROR_TYPES.NETWORK]: IMAGES.DISCONNECTED,
  [SYSTEM_ERROR_TYPES.UAA_CONNECTION]: IMAGES.DISCONNECTED,
  [SYSTEM_ERROR_TYPES.FORBIDDEN]: IMAGES.FORBIDDEN,
  [SYSTEM_ERROR_TYPES.GENERIC_500]: IMAGES.SERVER_ERROR
};
export const DEFAULT_ERROR_IMAGE = ERROR_IMAGES[SYSTEM_ERROR_TYPES.GENERIC_500];

export const ACTIONS = {
  LOGOUT: 'LOGOUT',
  NONE: 'NONE'
};

export const ERROR_ACTIONS = {
  [SYSTEM_ERROR_TYPES.FORBIDDEN]: ACTIONS.LOGOUT
}
export const DEFAULT_ERROR_ACTION = ACTIONS.NONE;