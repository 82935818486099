import { ACTIONS } from './config.consts';

export const reset = () => ({
  type: ACTIONS.RESET
});

export const networkError = () => ({
  type: ACTIONS.NETWORK_ERROR
});

export const uaaConnectionError = () => ({
  type: ACTIONS.UAA_CONNECTION_ERROR
});

export const generic500Error = () => ({
  type: ACTIONS.GENERIC_500_ERROR
});

export const forbiddenError = () => ({
  type: ACTIONS.FORBIDDEN_ERROR
});