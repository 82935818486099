import { all, call, takeEvery, put } from 'redux-saga/effects';
import { bulkUsersApi } from '../../../services';
import parseError from '../../__helpers__/parseApiError';

import { ACTIONS } from '../users.consts';
import * as userActions from '../users.actions';

export function* editUserRequest(action) {
  const { id, ...apiPayload } = action.payload;

  try {
    yield call(bulkUsersApi.update, { id, data: apiPayload });
    yield put(userActions.editUserRequestSuccess());
  } catch (error) {
    console.error(error);
    const errorMessage = parseError(error);
    yield put(userActions.editUserRequestFailure({ errorMessage }));
  }
}

function* editUserRequestSaga() {
  yield takeEvery(ACTIONS.EDIT_USER_REQUEST, editUserRequest);
}

export default function* rootEditUserRequestSaga() {
  yield all([
    editUserRequestSaga()
  ]);
}

