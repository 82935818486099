import { groupsApi } from '../../../services';
import { ASC_DESC } from '../../../App.consts';

async function datasource (query) {
  // expect context to be bound to datasource
  const {
    groupsPageRequest,
    groups,
    groupsPageRequestSuccess
  } = this;
  const params = transformParams(query);
  groupsPageRequest();
  const { data } = await groupsApi.getAll({ params });
  const response = transformResponse(data, groups);
  groupsPageRequestSuccess({
    groupsCurrentPage: response.data.map(group => group.displayName),
    totalGroups: response.totalCount
  });
  return response;
}

export const transformParams = (query) => {
  const params = {};

  // Pagination
  params.startIndex = ((query.page) * query.pageSize) + 1;
  params.count = query.pageSize;

  // SortBy
  if (query.orderBy) {
    params.sortBy = query.orderBy.sortByField || query.orderBy.field;
    params.sortOrder = ASC_DESC[query.orderDirection];
  }

  //Search
  if (query.search) {
    params.filter = `displayName co "${query.search}"`;
  }
  return params;
}

export const transformResponse = (response, groups) => {
  const { resources, startIndex, itemsPerPage, totalResults } = response;

  const data = resources.map(group => ({
    id: group.id,
    displayName: group.displayName,
    tableData: { checked: groups.includes(group.displayName)}
  }));

  const page = startIndex < itemsPerPage ? 0 : Math.floor(startIndex/itemsPerPage);
  return {
    data,
    page,
    totalCount: totalResults
  }
};


export default datasource;