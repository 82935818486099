import { ACTIONS, INITIAL_STATE } from './import.consts';
import { DateTime } from 'luxon';
import { now } from '../../services/dateTime/dateTime.utils';

export default function importReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case ACTIONS.RESET: {
      return INITIAL_STATE;
    }
    case ACTIONS.ALL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        results: null,
        summary: {
          ...state.summary,
          startTime: now()
        }
      };
    }
    case ACTIONS.ALL_SUCCESS: {
      const { results } = action.payload;
      const { summary } = state;
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        results,
        summary: computeResultSummary(summary, results)
      };
    }
    case ACTIONS.ALL_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        isLoading: false,
        errorMessage: message,
        results: null,
        summary: INITIAL_STATE.summary
      };
    }
    default:
      return state;
  }
}

export function computeResultSummary(summary, results) {
  const endTime = now();

  // duration
  const start = DateTime.fromMillis(summary.startTime);
  const end = DateTime.fromMillis(endTime);
  const durationObj = start.diff(end, ['milliseconds', 'seconds', 'minutes', 'hours']).toObject();
  let duration;
  if (durationObj.hours !== 0) {
    duration = `${Math.abs(durationObj.hours)} Hour(s)`;
  } else if (durationObj.minutes !== 0) {
    duration = `${Math.abs(durationObj.minutes)} Minute(s)`;
  } else if (durationObj.seconds !== 0) {
    duration = `${Math.abs(durationObj.seconds)} Second(s)`;
  } else {
    duration = `${Math.abs(durationObj.milliseconds)} Milliseconds`;
  }

  // successes & failures
  let successes = 0;
  let failures = 0;
  results.forEach(result => {
    if (result.success) {
      successes++;
    } else {
      failures++;
    }
  });

  return {
    ...summary,
    endTime,
    duration,
    total: results.length,
    successes,
    failures
  };
}