import React from 'react';
import Button from '@fh-components/button';
import { Form } from '@fh-components/form';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pluralize from 'react-pluralize';

import If from '../../If/If';
import ModalMessage from '../ModalMessage/ModalMessage';
import UsersListGroupsList from  '../UsersList.GroupsList';
import AddGroups from '../../AddGroups/AddGroups';
import { SCHEMA, FORM_OPTIONS } from './CreateNewUserModal.consts';

import './CreateNewUserModal.scss';

function CreateNewUserModal(props) {
  //properties
  const {
    modalLoading,
    errorMessage,
    groupsLoading,
    groups,
    newGroups,
  } = props;

  const schema = _.cloneDeep(SCHEMA);

  //functions
  const {
    closeCreateNewUserModal,
    newGroupsChange,
    createUserRequest
  } = props;

  const handleAddGroups = names => {
    const deletions = newGroups.filter(name => !names.includes(name));
    const additions = names.filter(name => !newGroups.includes(name));

    if (deletions.length) {
      newGroupsChange({ newGroups: newGroups.filter(name => !deletions.includes(name)) });
    } else {
      newGroupsChange({ newGroups: [...newGroups, ...additions] });
    }
  };

  const handleSubmit = (values) => {
    const formValues = {...values};
    formValues.generatePassword = formValues.generatePassword === 'yes' ? true : false;
    createUserRequest(formValues);
  };

  //disable form fields if loading
  if (modalLoading) {
    schema.forEach(formfield => formfield.disabled = true);
  }

  return (
    <Dialog className='create-new-user-modal' open={true} fullWidth={true} maxWidth='sm'>
      <Form schema={schema} options={FORM_OPTIONS}
        onSubmit={(values) => handleSubmit(values)}>
        {(props) => (
          <>
            <DialogTitle>
              Create A New User
            </DialogTitle>
            <DialogContent>
              <div className='form-fields'>
                {props.renderFormFields(schema)}
              </div>
              <div className={`groupsList-container ${groupsLoading ? 'loading' : ''}`}>
                <div className='loader'>
                  <CircularProgress color='secondary' />
                </div>
                <UsersListGroupsList isLoading={modalLoading} />
              </div>
              <div className='addGroups-container'>
                <AddGroups
                  label='Add new groups'
                  placeholder='Enter group name...'
                  helperText='New groups will be created upon submission'
                  isLoading={modalLoading}
                  onChange={names => handleAddGroups(names)}
                />
              </div>
            </DialogContent>

            <DialogActions>
              <If condition={!errorMessage}>
                <ModalMessage visible={true}>
                  <Pluralize singular='Group' plural='Groups' count={groups.length + newGroups.length} /> Selected
                </ModalMessage>
              </If>
              <If condition={!!errorMessage}>
                <ModalMessage visible={true}>
                  {errorMessage}
                </ModalMessage>
              </If>
              <Button
                color={Button.Colors.Primary}
                type='submit'
                loading={modalLoading}
                disabled={modalLoading}
                >
                  Create User
              </Button>
              <Button
                color={Button.Colors.Secondary}
                onClick={closeCreateNewUserModal}
                loading={modalLoading}
                disabled={modalLoading}
                >
                  Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
}

export default CreateNewUserModal;