export const ACTIONS = {
  USER_GROUPS_REQUEST: 'user-groups/request-groups',
  USER_GROUPS_REQUEST_LOADING: 'user-groups/request-loading',
  USER_GROUPS_REQUEST_SUCCESS: 'user-groups/request-success',
  USER_GROUPS_REQUEST_FAILURE: 'user-groups/request-failure',

  CREATE_USER_GROUP_REQUEST: 'user-groups/create-user-group-request',
  CREATE_USER_GROUP_LOADING: 'user-groups/create-user-group-loading',
  CREATE_USER_GROUP_SUCCESS: 'user-groups/create-user-group-success',
  CREATE_USER_GROUP_FAILURE: 'user-groups/create-user-group-failure',
  
  DELETE_USER_GROUP_REQUEST: 'user-groups/delete-user-group-request',
  DELETE_USER_GROUP_LOADING: 'user-groups/delete-user-group-loading',
  DELETE_USER_GROUP_SUCCESS: 'user-groups/delete-user-group-success',
  DELETE_USER_GROUP_FAILURE: 'user-groups/delete-user-group-failure',

  CLEAR_LOADING_ERROR: 'user-groups/clear-loading-error'
};

export const PROPS = {
  ROOT: 'userGroups',
  LOADING: 'loading',
  ERROR: 'error',
  LIST: 'list'
};

export const INITIAL_STATE = {
  [PROPS.LOADING]: false,
  [PROPS.ERROR]: null,
  [PROPS.LIST]: []
};
