import React from 'react';
import AddToGroupsIcon from '@material-ui/icons/People';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@fh-components/button';
import Chip from '@material-ui/core/Chip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Add from '@material-ui/icons/Add';
import RemoveFromGroupsIcon from '@material-ui/icons/PeopleOutline';
import Toolbar from '@material-ui/core/Toolbar';
import If from '../../If/If';

import './UsersList.Toolbar.scss';


function UsersListToolbar(props) {
  //properties
  const {
    selected,
    totalUsers,
    pageSize,
    selectAllItems
  } = props;

  //functions
  const {
    openBulkAddUsersToGroupModal,
    openBulkRemoveUsersFromGroupModal,
    openBulkDeleteUsersModal,
    openBulkUnlockAccountsModal,
    openCreateNewUserModal,
    openEditUserModal,
    clearSelection,
    setSelectAllItems
  } = props;

  let selectString = `User${selected > 1 ? 's' : ''} selection`;
  if(selectAllItems) {
    selectString = `All Users selected`
  }

  // FIXME remove after #EMS-1469 changes
  const enableSelectAll = false;

  return (
    <AppBar position='static' className='users-list-toolbar'>
      <Toolbar>
        <Grid container direction='row' justify='space-between' alignItems='flex-start'>
          <Grid item>
          <div className={`left-side`}>
              <If condition={selected < 1}>
                <Button
                  color={Button.Colors.Primary}
                  icon={Add}
                  onClick={openCreateNewUserModal}
                  title="Create new user"
                ></Button>
                </If>
              <If condition={selected >=1}>
                 <Chip
                    onDelete={clearSelection}
                    avatar={<Avatar>{selectAllItems ? totalUsers : selected}</Avatar>}
                    label={selectString} variant='outlined'
                  />
                  <Divider flexItem orientation='vertical' />
              </If>
              <If condition={enableSelectAll && selected === pageSize && selected < totalUsers && !selectAllItems}>
                <Button color={Button.Colors.Primary}
                className="select-all-button"
                onClick={() => setSelectAllItems({selectAllItems:true})}
                >
                  Select all {totalUsers} users in the system
                </Button>
              </If>
              <If condition={selected === 1}>
                  <Button
                    variant='outlined'
                    icon={EditIcon}
                    onClick={openEditUserModal}
                  >Edit User</Button>
              </If>
              <If condition={selected >=1}>
                <Button
                    variant='outlined'
                    icon={DeleteForeverIcon}
                    onClick={openBulkDeleteUsersModal}
                  >Delete</Button>
              </If>
              <If condition={selected > 1}>
              <Button
                    variant='outlined'
                    icon={AddToGroupsIcon}
                    onClick={openBulkAddUsersToGroupModal}
                  >Add To Groups</Button>
                  <Button
                    variant='outlined'
                    icon={RemoveFromGroupsIcon}
                    onClick={openBulkRemoveUsersFromGroupModal}
                  >Remove From Groups</Button>
              </If>
              <If condition={selected >=1}>
              <Button
                    variant='outlined'
                    icon={LockOpenIcon}
                    onClick={openBulkUnlockAccountsModal}
                  >Unlock</Button>
              </If>
              </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );

}

export default UsersListToolbar;