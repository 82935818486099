import React from 'react';
import Button from '@fh-components/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pluralize from 'react-pluralize';

import If from '../../If/If';
import ModalMessage from '../ModalMessage/ModalMessage';
import UsersListGroupsList from  '../UsersList.GroupsList';
import AddGroups from '../../AddGroups/AddGroups';

import './BulkAddUsersToGroupModal.scss';

function BulkAddUsersToGroupModal(props) {
  //properties
  const {
    modalLoading,
    errorMessage,
    selectedRows,
    groupsLoading,
    groups,
    newGroups,
  } = props;

  //functions
  const {
    closeBulkAddUsersToGroupModal,
    newGroupsChange,
    bulkAddUsersToGroupRequest
  } = props;

  const usersCount = selectedRows.length;
  const groupsCount = groups.length + newGroups.length;

  const handleAddGroups = (names => {
    const deletions = newGroups.filter(name => !names.includes(name));
    const additions = names.filter(name => !newGroups.includes(name));

    if (deletions.length) {
      newGroupsChange({ newGroups: newGroups.filter(name => !deletions.includes(name)) });
    } else {
      newGroupsChange({ newGroups: [...newGroups, ...additions] });
    }
  });
  return(
    <Dialog className='bulk-add-users-to-group-modal' open={true} fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        Bulk Add{' '}
        <Pluralize singular='User' plural='Users' count={usersCount} />{' '}
        to Groups</DialogTitle>
      <DialogContent>
        <div className={`groupsList-container ${groupsLoading ? 'loading' : ''}`}>
          <div className='loader'>
            <CircularProgress color='secondary' />
          </div>
          <UsersListGroupsList isLoading={modalLoading} />
        </div>
        <div className='addGroups-container'>
          <AddGroups
            label='Add new groups'
            placeholder='Enter group name...'
            helperText='New groups will be created upon submission'
            isLoading={modalLoading}
            onChange={names => handleAddGroups(names)}
          />
        </div>
        <If condition={!errorMessage}>
          <ModalMessage visible={groupsCount > 0}>
              Add{' '}
              <strong>
                <Pluralize singular='User' plural='Users' count={usersCount} />{' '}
              </strong> to{' '}
              <Pluralize singular='Group' plural='Groups' count={groupsCount} />{' '}
          </ModalMessage>
        </If>
        <If condition={!!errorMessage}>
          <ModalMessage visible={true}>
            {errorMessage}
          </ModalMessage>
        </If>
      </DialogContent>

      <DialogActions>
        <Button
          color={Button.Colors.Secondary}
          onClick={closeBulkAddUsersToGroupModal}
          loading={modalLoading}
          disabled={modalLoading}
          >
            Cancel
        </Button>
        <Button
          color={Button.Colors.Primary}
          onClick={bulkAddUsersToGroupRequest}
          loading={modalLoading}
          disabled={groupsCount < 1 || modalLoading}
          >
          Add to Groups
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkAddUsersToGroupModal;