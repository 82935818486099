import { connect } from 'react-redux';
import {
  bulkUnlockAccountsRequest,
  closeBulkUnlockAccountsModal
} from '../../../store/users/users.actions';
import BulkUnlockAccountsModal from './BulkUnlockAccountsModal';

const mapStateToProps = (state) => ({
  modalLoading: state.users.modalLoading,
  errorMessage: state.users.errorMessage,
  selectedRows: state.users.selectedRows
});

const mapDispatchToProps = {
  bulkUnlockAccountsRequest,
  closeBulkUnlockAccountsModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkUnlockAccountsModal);