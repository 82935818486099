import Axios from 'axios';
import path from 'path';

/**
 * PATCHs to endpoint
 * @param { id, data } props
 * @description data is body
 */
export function patchApi({ id, data } = {}) {
  let url = path.join(this.endpointPrefix, this.endpoint, `${id}`);

  return Axios.request({
    url,
    method: 'PATCH',
    data,
    baseURL: this.baseURL,
    ...this.requestProps
  });
}
