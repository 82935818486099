import authSagas from './auth/auth.sagas';
import importSagas from './import/import.sagas';
import userSagas from './users/users.sagas';
import configSagas from './config/config.sagas';
import userGroupsSagas from './userGroups/userGroups.sagas';

export default [
  ...authSagas,
  ...importSagas,
  ...userSagas,
  ...configSagas,
  ...userGroupsSagas
];