export const ACTIONS = {
  ////////////////////////////////////////  SELECTION STATE  ////////////////////////////////////////
  SET_REFRESH_TABLE: 'users/set-refresh-table',
  USERS_REQUEST: 'users/users-request',
  USERS_REQUEST_SUCCESS: 'users/users-request-success',
  SELECTION_CHANGE: 'users/selection-change',
  CLEAR_SELECTION: 'users/clear-selection',
  GROUPS_CHANGE: 'users/groups-change',
  NEW_GROUPS_CHANGE: 'users/new-groups-change',
  GROUPS_PAGE_REQUEST: 'users/groups-page-request',
  GROUPS_PAGE_REQUEST_SUCCESS: 'users/groups-page-success',
  SET_CURRENT_PAGE_SIZE: 'users/set-current-page-size', // defaults to 20
  SELECT_ALL_ITEMS: 'users/select-all-items', // defaults to current page size or visible number of rows
  ////////////////////////////////////////  MODAL OPEN STATE  ////////////////////////////////////////
  OPEN_BULK_ADD_USERS_TO_GROUP_MODAL: 'users/open-bulk-add-users-to-group',
  CLOSE_BULK_ADD_USERS_TO_GROUP_MODAL: 'users/close-bulk-add-users-to-gorup-modal',
  OPEN_BULK_REMOVE_USERS_FROM_GROUP_MODAL: 'users/open-bulk-remove-users-from-group',
  CLOSE_BULK_REMOVE_USERS_FROM_GROUP_MODAL: 'users/close-bulk-remove-users-from-gorup-modal',
  OPEN_BULK_DELETE_USERS_MODAL: 'users/open-bulk-delete-users-modal',
  CLOSE_BULK_DELETE_USERS_MODAL: 'users/close-bulk-delete-users-modal',
  OPEN_BULK_UNLOCK_ACCONTS_MODAL: 'users/open-bulk-unlock-accounts-modal',
  CLOSE_BULK_UNLOCK_ACCONTS_MODAL: 'users/close-bulk-unlock-accounts-modal',
  OPEN_CREATE_NEW_USER_MODAL: 'users/open-create-new-user-modal',
  CLOSE_CREATE_NEW_USER_MODAL: 'users/close-create-new-user-modal',
  OPEN_EDIT_USER_MODAL: 'users/open-edit-user-modal',
  CLOSE_EDIT_USER_MODAL: 'users/close-edit-user-modal',
////////////////////////////////////////  MODAL REQUEST STATE  ////////////////////////////////////////
  BULK_ADD_USERS_TO_GROUP_REQUEST: 'users/bulk-add-users-to-gorup-request',
  BULK_REMOVE_USERS_FROM_GROUP_REQUEST: 'users/bulk-remove-users-from-gorup-request',
  BULK_DELETE_USERS_REQUEST: 'users/bulk-delete-users-request',
  BULK_UNLOCK_ACCOUNTS_REQUEST: 'users/bulk-unlock-accounts-request',
  BULK_REQUEST_SUCCESS: 'users/bulk-request-success',
  BULK_REQUEST_FAILURE: 'users/bulk-request-failure',
  CREATE_USER_REQUEST: 'users/create-user-reqeust',
  CREATE_USER_SUCCESS: 'users/create-user-success',
  CREATE_USER_FAILURE: 'users/create-user-failure',
  EDIT_USER_REQUEST: 'users/edit-user-reqeust',
  EDIT_USER_SUCCESS: 'users/edit-user-success',
  EDIT_USER_FAILURE: 'users/edit-user-failure'
};

export const INITIAL_STATE = {
  modalLoading: false,
  errorMessage: '',
  refreshTable: false,
  usersLoading: false,
  totalUsers: 0,
  currentPage: [],
  selectedRows: [],
  groupsLoading: false,
  totalGroups: 0,
  groups: [],
  groupsCurrentPage: [],
  newGroups: [],
  bulkUnlockAccountsModalOpen: false,
  bulkDeleteUsersModalOpen: false,
  bulkAddUsersToGroupModalOpen: false,
  bulkRemoveUsersFromGroupModalOpen: false,
  createNewUserModalOpen: false,
  editUserModalOpen: false,
  selectAllItems: false,
  pageSize: 20
};