import { connect } from 'react-redux';
import {
  createUserRequest,
  closeCreateNewUserModal,
  groupsChange,
  newGroupsChange
} from '../../../store/users/users.actions';

import CreateNewUserModal from './CreateNewUserModal';

const mapStateToProps = (state) => ({
  modalLoading: state.users.modalLoading,
  errorMessage: state.users.errorMessage,
  selectedRows: state.users.selectedRows,
  groupsLoading: state.users.groupsLoading,
  groups: state.users.groups,
  newGroups: state.users.newGroups
});

const mapDispatchToProps = {
  createUserRequest,
  closeCreateNewUserModal,
  groupsChange,
  newGroupsChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewUserModal);