import { usernameIsUnique } from './CreateNewUserModal.util';

export const SCHEMA = [{
  type: 'input',
  label: 'Username',
  selector: 'userName',
  name: 'userName',
  inline: false,
  subType: 'text',
  props: {
    placeholder: 'Enter a valid email address...',
    variant: 'outlined',
    autoComplete: 'off',
    style: {width: '100%'} //override InputCommonStyles width
  },
  rules: {
    required: 'Username is required',
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, //same regex as used by backend
      message: 'Please enter a valid email'
    },
    validate: {
      unique: usernameIsUnique
    }
  }
}, {
  type: 'input',
  label: 'First Name',
  name: 'firstName',
  selector: 'firstName',
  inline: false,
  subType: 'text',
  props: {
    placeholder: 'Enter first name...',
    variant: 'outlined',
    autoComplete: 'off',
    style: {width: '100%'} //override InputCommonStyles width
  },
  rules: {
    required: 'First name is required',
    maxLength: {
      value: 255,
      message: 'Cannot exceed 255 characters'
    }
  }
}, {
  type: 'input',
  label: 'Last Name',
  name: 'lastName',
  selector: 'lastName',
  inline: false,
  subType: 'text',
  props: {
    placeholder: 'Enter last name...',
    variant: 'outlined',
    autoComplete: 'off',
    style: {width: '100%'} //override InputCommonStyles width
  },
  rules: {
    required: 'Last name is required',
    maxLength: {
      value: 255,
      message: 'Cannot exceed 255 characters'
    }
  }
}, {
  type: 'radio',
  label: 'Generate Password?',
  name: 'generatePassword',
  selector: 'generatePassword',
  inline: true,
  props: {
    placeholder: 'Enter last name...',
    variant: 'outlined',
    style: {width: '100%'} //override InputCommonStyles width
  },
  options: [{
    label: 'Yes',
    value: 'yes'
  }, {
    label: 'No',
    value: 'no'
  }]
}, {
  type: 'input',
  subType: 'password',
  label: 'Password',
  name: 'password',
  selector: 'password',
  dependsOn: 'generatePassword',
  dependsOnValue: 'no',
  props: {
    placeholder: 'Enter password...',
    variant: 'outlined',
    autoComplete: 'off',
    style: {width: '100%'} //override InputCommonStyles width
  },
  rules: {
    minLength: {
      value: 8,
      message: 'Must be at least 8 characters long'
    },
    pattern: {
      value: /^(?:(?=.*[a-z])(?=.*[A-Z]).*)$/,
      message: 'Must have at least 1 upper case and 1 lower case'
    },
    maxLength: {
      value: 255,
      message: 'Cannot exceed 255 characters'
    },
    required: 'Password is required (if not generating a password)'
  }
}];

export const DEFAULT_VALUES = {
  generatePassword: 'yes'
};

export const FORM_OPTIONS = {
  defaultValues: DEFAULT_VALUES
};