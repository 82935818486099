import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreTwoTone from '@material-ui/icons/ExpandMoreTwoTone';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

export default function Summary({ summary }) {

  function renderCounts({ total, successes, failures }) {
    return (
      <Grid item>
        <Grid container direction="column" justify="center" alignItems="center" >
          <Grid item>
            <Typography variant="h3" gutterBottom>
            {successes} Successes &amp; {failures} Failures
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {total} Total
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderTimes({ duration, startTime, endTime }) {
    return (
      <Grid item>
        <Grid container direction="column" justify="center" alignItems="center" >
          <Grid item>
            <Typography variant="h3" gutterBottom>
              {duration}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {new Date(startTime).toLocaleTimeString()} - {new Date(endTime).toLocaleTimeString()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreTwoTone htmlColor='white'/>}
        aria-controls="summary"
        id="summary"
      >
        <Typography variant='h6'>
          {summary.successes} Successes &amp; {summary.failures} Failures
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row" justify="space-around" alignItems="center" >
          {renderCounts(summary)}
          <Divider orientation="vertical" flexItem />
          {renderTimes(summary)}
        </Grid>


      </AccordionDetails>
    </Accordion>
  );
}