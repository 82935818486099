import { ACTIONS, INITIAL_STATE } from './auth.consts';

export default function authReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case ACTIONS.LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true
      };

    }
    case ACTIONS.LOGIN_SUCCESS: {
      const { token, scope, expiresIn } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        errorMessage: null,
        token,
        scope,
        expiresIn
      };
    }
    case ACTIONS.LOGIN_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        errorMessage: message
      };
    }
    case ACTIONS.CLEAR_AUTH: {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
    default:
      return state;
  }
}