import { connect } from 'react-redux';
import {
  setRefreshTable,
  selectionChange,
  usersRequest,
  usersRequestSuccess,
  setCurrentPageSize
} from '../../store/users/users.actions';
import UsersList from './UsersList';

const mapStateToProps = (state) => ({
  refreshTable: state.users.refreshTable,
  selectedRows: state.users.selectedRows,
  currentPage: state.users.currentPage,
  totalUsers: state.users.totalUsers,
  usersLoading: state.users.usersLoading,
  bulkAddUsersToGroupModalOpen: state.users.bulkAddUsersToGroupModalOpen,
  bulkRemoveUsersFromGroupModalOpen: state.users.bulkRemoveUsersFromGroupModalOpen,
  bulkDeleteUsersModalOpen: state.users.bulkDeleteUsersModalOpen,
  bulkUnlockAccountsModalOpen: state.users.bulkUnlockAccountsModalOpen,
  createNewUserModalOpen: state.users.createNewUserModalOpen,
  EditUserModalOpen: state.users.editUserModalOpen
})

const mapDispatchToProps = {
  setRefreshTable,
  usersRequest,
  usersRequestSuccess,
  selectionChange,
  setCurrentPageSize
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList);