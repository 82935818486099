import { createApi } from '../base.api/create.base.api';
import { ENDPOINTS, BE_BASE_URL, BE_API_PREFIX } from '../api.consts';

function userApi() {
  const context = {
    endpoint: ENDPOINTS.USER,
    baseURL: BE_BASE_URL,
    endpointPrefix: BE_API_PREFIX
  };

  // only implement the methods we use
  return {
    // get: getApi.bind(context),
    // getAll: getAllApi.bind(context),
    create: createApi.bind(context),
    // update: updateApi.bind(context),
    // delete: deleteApi.bind(context),
    // patch: patchApi.bind(context)
  };
}

export default userApi();