import { ACTIONS } from './import.consts';

export const reset = () => ({
  type: ACTIONS.RESET
});

export const allRequest = ({ formData, groups }) => ({
  type: ACTIONS.ALL_REQUEST,
  payload: { formData, groups }
});

export const allSuccess = ({ results }) => ({
  type: ACTIONS.ALL_SUCCESS,
  payload: { results }
});

export const allFailure = ({ message }) => ({
  type: ACTIONS.ALL_FAILURE,
  payload: { message }
});