import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@fh-components/button';
import Chip from '@material-ui/core/Chip';
import { DropzoneArea } from 'material-ui-dropzone';
import MaterialTable from 'material-table';
import GetAppIcon from '@material-ui/icons/GetApp';
import Typography from '@material-ui/core/Typography';

import Loader from '../Loader/Loader';
import tableIcons from '../TableIcons';
import AddGroups from '../AddGroups/AddGroups';
import Summary from './Summary/Summary';

import { now } from '../../services/dateTime/dateTime.utils';
import { BULK_ACTIONS, TABLE_COLUMNS, TABLE_OPTIONS, EXAMPLE_FILE_URL } from './ImportUtility.consts';
import { API_ACTIONS as BULK_ACTIONS_MAP } from '../../store/import/import.consts';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ROUTES } from '../../App.consts';

import './importUtility.scss';

export default class ImportUtility extends Component {

  state = {
    file: undefined,
    groups: [],
    bulkAction: BULK_ACTIONS_MAP.ALL,
    groupsResetKey: now()
  };

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      allRequest
    } = this.props;
    const { file, groups, bulkAction } = this.state;
    const searlizedGroups = groups.length ? groups.join('::') : '';
    const formData = new FormData();
    formData.append('file', file);

    if (bulkAction === 'ALL') {
      allRequest({ formData, groups: searlizedGroups });
    }
  }

  removeFile() {
    this.setState({ file: undefined });
  }

  render() {
    const { isLoading } = this.props;
    return (
      <Container className='import-utility' maxWidth='md'>
        {isLoading ? <Loader /> : ''}
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Grid className='container' container direction='column' justify='center'
              alignItems='stretch' spacing={4}>
            {this.renderDropzone()}
            {this.renderFileList()}
            {/* {this.renderActionSelect()}*/}
            {this.renderGroupsField()}
            {this.renderMessage()}
            {this.renderButtons()}
            {this.renderSummary()}
            {this.renderSuccessPopup()}
            {this.renderResults()}
          </Grid>
        </form>
      </Container>
    );
  }

  renderDropzone() {
    const { isLoading } = this.props;
    const { file } = this.state;
    const handleFileChange = (files) => {
      this.setState({ file: files[0] });
    };
    return (
      <Grid item className='dropzone-container'>
        <DropzoneArea filesLimit={1} showAlerts={['error']} disabled={isLoading}
          acceptedFiles={['.csv', '.xls', '.xlsx']} fileObjects={[file]}
          showPreviews={false} showPreviewsInDropzone={false} useChipsForPreview={false}
          dropzoneText='Drop your file here or click to browse.' onChange={handleFileChange}
        />
        <a className='example-file-download' href={EXAMPLE_FILE_URL} download target='_blank' rel='noopener noreferrer'>
          <Typography variant='overline'>
            <GetAppIcon fontSize='small' /> Sample Template
          </Typography>
        </a>
      </Grid>
    );
  }

  renderFileList() {
    const { file } = this.state;
    return (
      <Grid item className='file-list'>
        {file ? <Chip label={file.name} onDelete={this.removeFile.bind(this)} /> : ''}
      </Grid>
    );
  }

  renderGroupsField() {
    const { isLoading, groups } = this.props;
    const { groupsResetKey } = this.state;
    return (
      <Grid item key={groupsResetKey}>
        <AddGroups
          onChange={(values) => this.setState({groups: values})}
          isLoading={isLoading} value={groups}
          helperText='Users will be added to these groups, as well as those defined in the spreadsheet'
        />
      </Grid>
    );
  }

  renderMessage() {
    const { errorMessage } = this.props;
    return (
      <Grid item>
        <span className='error-message'>{ errorMessage }</span>
      </Grid>
    );
  }

  renderSummary() {
    const { results, summary } = this.props;
    if (results) {
      return (
        <Grid item>
          <Summary summary={summary} />
        </Grid>
      );
   }
  }

  renderSuccessPopup() {
    const {history, results} = this.props;
    const {ROOT} = ROUTES;
    const {file} = this.state;
  
    return <Dialog className='import-success-modal' open={!!file && !!results} fullWidth={false} maxWidth='sm'>
      <DialogTitle>Important</DialogTitle>
      <DialogContent>
        Import succesful. You may check the results table to see all the operations that were executed.
      </DialogContent>
      <DialogActions>
        <Button
          color={Button.Colors.Secondary}
          onClick={() => { this.setState(curr => { return {...curr, file: undefined}; })}}
          >
            Dismiss
        </Button>
        <Button color={Button.Colors.Primary}
        onClick={() => { history.push(`${ROOT}`)}}>
          Go to Users List</Button>
      </DialogActions>
    </Dialog>
  }

  renderResults() {
    const { results } = this.props;
    if (results) {
      return (
        <Grid item>
          <MaterialTable
            title='Results' columns={TABLE_COLUMNS} options={TABLE_OPTIONS} data={results}
            icons={tableIcons}
          />
        </Grid>
      );
    }
  }

  renderButtons() {
    const { isLoading } = this.props;
    const { file } = this.state;
    const submitDisabled = isLoading || file === undefined;
    const handleReset = () => {
      const { reset } = this.props;
      reset();
      this.setState({
        file: undefined,
        groups: [],
        bulkAction: BULK_ACTIONS[0].key,
        groupsResetKey: now()
      });
    };
    return(
      <Grid item>
        <Grid container direction='row' justify='space-between' alignItems='flex-start'>
          <Grid item>
            <Button
              color={Button.Colors.SecondaryDestructive}
              disabled={isLoading} type='button'
              onClick={() => {handleReset()}}>
              Clear
            </Button>
          </Grid>
          <Grid item>
          <Button
            color={Button.Colors.Primary}
            disabled={submitDisabled}>
            Submit
          </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
