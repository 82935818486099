export const ACTIONS = {
  LOGIN_REQUEST: 'auth/login-request',
  LOGIN_SUCCESS: 'auth/login-success',
  LOGIN_FAILURE: 'auth/login-failure',
  LOGOUT: 'auth/logout',
  PERSIST_AUTH: 'auth/persist-auth',
  CLEAR_AUTH: 'auth/clear-auth'
};

export const INITIAL_STATE = {
  isAuthenticated: false,
  isLoading: false,
  errorMessage: null,
  token: null,
  refreshToken: null,
  scope: null,
  expiresIn: null
};