import { connect } from 'react-redux';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from './App.consts';
import { logout } from './store/auth/auth.actions';
import Login from './components/Login';
import ImportUtility from './components/ImportUtility';
import UserGroupsList from './components/UserGroupsList';
import UsersList from './components/UsersList';
import NavBar from './components/NavBar/NavBar';
import Error from './components/Error/Error';

function Routes(props) {
  const { ROOT, LOGIN, IMPORT_UTILITY, USER_GROUPS } = ROUTES;
  const { isAuthenticated, logout } = props;
  return(
    <>
      <NavBar isAuthenticated={isAuthenticated} logout={logout}/>
      <Switch>
        <Route path={`${ROOT}${USER_GROUPS}`}>
          {isAuthenticated ? (<UserGroupsList />) : (
            <Redirect to={ROUTES.LOGIN} />
          )}
        </Route>
        <Route path={`${ROOT}${IMPORT_UTILITY}`}>
          {isAuthenticated ? (<ImportUtility />) : (
            <Redirect to={ROUTES.LOGIN} />
          )}
        </Route>
        <Route path={`${ROOT}${LOGIN}`}>
          <Login />
        </Route>
        <Route path={`${ROOT}`}>
          {isAuthenticated ? (<UsersList />) : (
            <Redirect to={ROUTES.LOGIN} />
          )}
        </Route>
      </Switch>
    </>
  );
}

export function AppRoutes(props) {
  const { systemError, systemErrorType, logout } = props;
  if (systemError) {
    return <Error systemErrorType={systemErrorType} logout={logout}/>;
  } else {
    return <Routes {...props} />;
  }

}

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;
  const { systemError, systemErrorType } = state.config;
  return { isAuthenticated, systemError, systemErrorType };
};

const mapDispatchToProps = {
  logout
}

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(AppRoutes);

