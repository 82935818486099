import { createApi } from '../base.api/create.base.api';
import { ENDPOINTS, BULK_OPERATION_TIMEOUT, BE_BASE_URL, BE_API_PREFIX } from '../api.consts';

function bulkOperationApi() {
  const context = {
    endpoint: ENDPOINTS.BULK_OPERATION,
    baseURL: BE_BASE_URL,
    endpointPrefix: BE_API_PREFIX,
    requestProps: {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: BULK_OPERATION_TIMEOUT
    }
  };

  // only implement the methods we use
  return {
    // get: getApi.bind(context),
    // getAll: getAllApi.bind(context),
    create: createApi.bind(context),
    // update: updateApi.bind(context),
    // delete: deleteApi.bind(context),
    // patch: patchApi.bind(context)
  };
}

export default bulkOperationApi();