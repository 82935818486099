import { connect } from 'react-redux';
import {
  bulkDeleteUsersRequest,
  closeBulkDeleteUsersModal
} from '../../../store/users/users.actions';
import BulkDeleteUsersModal from './BulkDeleteUsersModal';

const mapStateToProps = (state) => ({
  modalLoading: state.users.modalLoading,
  errorMessage: state.users.errorMessage,
  selectedRows: state.users.selectedRows
});

const mapDispatchToProps = {
  bulkDeleteUsersRequest,
  closeBulkDeleteUsersModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkDeleteUsersModal);