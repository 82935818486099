import { connect } from 'react-redux';
import {
  openBulkAddUsersToGroupModal,
  openBulkRemoveUsersFromGroupModal,
  openBulkDeleteUsersModal,
  openBulkUnlockAccountsModal,
  openCreateNewUserModal,
  openEditUserModal,
  clearSelection,
  setSelectAllItems
} from '../../../store/users/users.actions';
import UsersListToolbar from './UsersList.Toolbar';

const mapStateToProps = (state) => {
  return {
    selected: state.users.selectedRows.length,
    totalUsers: state.users.totalUsers,
    pageSize: state.users.pageSize,
    selectAllItems: state.users.selectAllItems
  };
}

const mapDispatchToProps = {
  openBulkAddUsersToGroupModal,
  openBulkRemoveUsersFromGroupModal,
  openBulkDeleteUsersModal,
  openBulkUnlockAccountsModal,
  openCreateNewUserModal,
  openEditUserModal,
  clearSelection,
  setSelectAllItems
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersListToolbar);