import { ACTIONS } from './users.consts';

////////////////////////////////////////  SELECTION STATE  ////////////////////////////////////////
export const setRefreshTable = ({ refreshTable }) => ({
  type: ACTIONS.SET_REFRESH_TABLE,
  payload: { refreshTable }
});

export const usersRequest = () => ({
  type: ACTIONS.USERS_REQUEST
});

export const usersRequestSuccess = ({ currentPage, totalUsers }) => ({
  type: ACTIONS.USERS_REQUEST_SUCCESS,
  payload: { currentPage, totalUsers }
});

export const selectionChange = ({ selectedRows }) => ({
  type: ACTIONS.SELECTION_CHANGE,
  payload: { selectedRows }
});

export const setCurrentPageSize = ({pageSize}) => ({
  type: ACTIONS.SET_CURRENT_PAGE_SIZE,
  payload: {pageSize}
});

export const setSelectAllItems = ({selectAllItems}) => ({
  type: ACTIONS.SELECT_ALL_ITEMS,
  payload: {selectAllItems}
});

export const clearSelection = () => ({
  type: ACTIONS.CLEAR_SELECTION
});

export const groupsChange = ({ groups }) => ({
  type: ACTIONS.GROUPS_CHANGE,
  payload: { groups }
});

export const newGroupsChange = ({ newGroups }) => ({
  type: ACTIONS.NEW_GROUPS_CHANGE,
  payload: { newGroups }
});

export const groupsPageRequest = () => ({
  type: ACTIONS.GROUPS_PAGE_REQUEST
});

export const groupsPageRequestSuccess = ({ groupsCurrentPage, totalGroups }) => ({
  type: ACTIONS.GROUPS_PAGE_REQUEST_SUCCESS,
  payload: { groupsCurrentPage, totalGroups }
});

////////////////////////////////////////  MODAL OPEN STATE  ////////////////////////////////////////
export const openBulkAddUsersToGroupModal = () => ({
  type: ACTIONS.OPEN_BULK_ADD_USERS_TO_GROUP_MODAL
});

export const closeBulkAddUsersToGroupModal = () => ({
  type: ACTIONS.CLOSE_BULK_ADD_USERS_TO_GROUP_MODAL
});

export const openBulkRemoveUsersFromGroupModal = () => ({
  type: ACTIONS.OPEN_BULK_REMOVE_USERS_FROM_GROUP_MODAL
});

export const closeBulkRemoveUsersFromGroupModal = () => ({
  type: ACTIONS.CLOSE_BULK_REMOVE_USERS_FROM_GROUP_MODAL
});

export const openBulkDeleteUsersModal = () => ({
  type: ACTIONS.OPEN_BULK_DELETE_USERS_MODAL
});

export const closeBulkDeleteUsersModal = () => ({
  type: ACTIONS.CLOSE_BULK_DELETE_USERS_MODAL
});

export const openBulkUnlockAccountsModal = () => ({
  type: ACTIONS.OPEN_BULK_UNLOCK_ACCONTS_MODAL
});

export const closeBulkUnlockAccountsModal = () => ({
  type: ACTIONS.CLOSE_BULK_UNLOCK_ACCONTS_MODAL
});

export const openCreateNewUserModal = () => ({
  type: ACTIONS.OPEN_CREATE_NEW_USER_MODAL
});

export const closeCreateNewUserModal = () => ({
  type: ACTIONS.CLOSE_CREATE_NEW_USER_MODAL
});

export const openEditUserModal = () => ({
  type: ACTIONS.OPEN_EDIT_USER_MODAL
});

export const closeEditUserModal = () => ({
  type: ACTIONS.CLOSE_EDIT_USER_MODAL
});

////////////////////////////////////////  MODAL REQUEST STATE  ////////////////////////////////////////
export const bulkAddUsersToGroupRequest = () => ({
  type: ACTIONS.BULK_ADD_USERS_TO_GROUP_REQUEST
});

export const bulkRemoveUsersFromGroupRequest = () => ({
  type: ACTIONS.BULK_REMOVE_USERS_FROM_GROUP_REQUEST
});

export const bulkDeleteUsersRequest = () => ({
  type: ACTIONS.BULK_DELETE_USERS_REQUEST
});

export const bulkUnlockAccountsRequest = () => ({
  type: ACTIONS.BULK_UNLOCK_ACCOUNTS_REQUEST
});

export const bulkRequestSuccess = () => ({
  type: ACTIONS.BULK_REQUEST_SUCCESS
});

export const bulkRequestFailure = ({ errorMessage }) => ({
  type: ACTIONS.BULK_REQUEST_FAILURE,
  payload: { errorMessage }
});

export const createUserRequest = ({ userName, firstName, lastName, generatePassword, password }) => ({
  type: ACTIONS.CREATE_USER_REQUEST,
  payload: { userName, firstName, lastName, generatePassword, password }
});

export const createUserRequestSuccess = () => ({
  type: ACTIONS.CREATE_USER_SUCCESS
});

export const createUserRequestFailure = ({ errorMessage }) => ({
  type: ACTIONS.CREATE_USER_FAILURE,
  payload: { errorMessage }
});

export const editUserRequest = ({
  id, userName, firstName, lastName, groupAdditions, groupDeletions
}) => ({
  type: ACTIONS.EDIT_USER_REQUEST,
  payload: {
    id, userName, firstName, lastName, groupAdditions, groupDeletions
  }
});

export const editUserRequestSuccess = () => ({
  type: ACTIONS.EDIT_USER_SUCCESS
});

export const editUserRequestFailure = ({ errorMessage }) => ({
  type: ACTIONS.EDIT_USER_FAILURE,
  payload: { errorMessage }
});