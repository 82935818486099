import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {
  ERROR_TITLES,
  DEFAULT_ERROR_TITLE,
  ERROR_MESSAGES,
  DEFAULT_ERROR_MESSAGE,
  ERROR_IMAGES,
  DEFAULT_ERROR_IMAGE,
  ACTIONS,
  ERROR_ACTIONS,
  DEFAULT_ERROR_ACTION
} from './Error.consts';
import './Error.scss';


export default function Error({ systemErrorType, logout }) {
  const title = ERROR_TITLES[systemErrorType] || DEFAULT_ERROR_TITLE;
  const message = ERROR_MESSAGES[systemErrorType] || DEFAULT_ERROR_MESSAGE;
  const image = ERROR_IMAGES[systemErrorType] || DEFAULT_ERROR_IMAGE;
  const actions = ERROR_ACTIONS[systemErrorType] || DEFAULT_ERROR_ACTION;
  return (
    <Grid className='error-container' container direction='column' justify='center' alignItems='center'>
      <Grid item>
        <Card>
          <CardMedia image={image} />
          <CardContent>
            <Typography variant='h5' component='h2'>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {message}
            </Typography>
          </CardContent>
          <Actions actions={actions} logout={logout} />
        </Card>
      </Grid>
    </Grid>
  );
}

function Actions(props) {
  const { actions, logout } = props;
  if (actions === ACTIONS.LOGOUT) {
    return (
      <CardActions>
        <Button color='secondary' fullWidth={true} onClick={logout}>
          Logout
        </Button>
      </CardActions>
    );
  } else {
    return null;
  }
}