import { connect } from 'react-redux';
import UserGroupsList from './UserGroupsList';
import { withRouter } from 'react-router-dom';
import { requestUserGroupList, deleteUserGroupRequest, createUserGroupRequest, clearLoadingAndError } from '../../store/userGroups/userGroups.actions';
import { selectLoading, selectError, selectUserGroupList } from '../../store/userGroups/userGroups.selector';

const mapStateToProps = (state) => {
  return {
    loading: selectLoading(state),
    error: selectError(state),
    userGroupList: selectUserGroupList(state)
  };
}

const mapDispatchToProps = {
  requestUserGroupList,
  deleteUserGroupRequest,
  createUserGroupRequest,
  clearLoadingAndError
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGroupsList));