import { connect } from 'react-redux';
import { loginRequest } from '../../store/auth/auth.actions';
import Login from './Login';

const mapStateToProps = (state) => {
  const { isAuthenticated, isLoading, errorMessage, username } = state.auth;
  return { isAuthenticated, isLoading, errorMessage, username };
}

const mapDispatchToProps = {
  loginRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);