import { connect } from 'react-redux';
import {
  bulkRemoveUsersFromGroupRequest,
  closeBulkRemoveUsersFromGroupModal,
  groupsChange
} from '../../../store/users/users.actions';
import BulkRemoveUsersFromGroupModal from './BulkRemoveUsersFromGroupModal';

const mapStateToProps = (state) => ({
  modalLoading: state.users.modalLoading,
  errorMessage: state.users.errorMessage,
  selectedRows: state.users.selectedRows,
  groups: state.users.groups
});

const mapDispatchToProps = {
  bulkRemoveUsersFromGroupRequest,
  closeBulkRemoveUsersFromGroupModal,
  groupsChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkRemoveUsersFromGroupModal);