export const ACTIONS = {
  RESET: 'config/reset',
  NETWORK_ERROR: 'config/network-error',
  UAA_CONNECTION_ERROR: 'config/uaa-connection-error',
  GENERIC_500_ERROR: 'config/generic-500-error',
  FORBIDDEN_ERROR: 'config/forbidden-error'
};

export const INITIAL_STATE = {
  systemError: false,
  systemErrorType: null
};

export const SYSTEM_ERROR_TYPES = {
  NETWORK: 'networkError',
  UAA_CONNECTION: 'UAAConnectionError',
  GENERIC_500: 'generic500',
  FORBIDDEN: 'forbidden'
}