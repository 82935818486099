import React, { Component } from "react";
import Button from '@fh-components/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import If from '../If/If';
import Loader from '../Loader/Loader';

export default class CreateUserGroupModal extends Component {

  state = {
    name: '',
    description: ''
  };

  componentDidUpdate(oldProps){
    const {rows: newRows} = this.props;
    const {rows: oldRows} = oldProps;
    if (newRows.length === oldRows.length +1) {
      this.handleClose();
    }
  }

  fieldUpdateHandler = (event) => {
    this.setState({[event?.target?.name]: event?.target?.value});
  }

  handleClose = () => {
    this.props.setCreateUserGroupModal(false);
  }

  handleCreate = () => {
    const requestObj = {
      name: this.state.name,
      description: this.state.description
    };

    this.props.createUserGroupRequest(requestObj);
  }

  validateForm = () => {
    const { name } = this.state;
    const  { rows } = this.props;

    const validationMessages = [];
    const existingRow = rows.find( row => row.displayName === name);
    if (existingRow) {
      validationMessages.push('User group with the same name exists already.');
    }
    return {
      isValid: validationMessages.length === 0,
      validationMessages
    }
  }

  submitForm = () => {
    const { isValid, validationMessages } = this.validateForm();
    if (isValid) {
      this.handleCreate();
    } else {
      console.log(validationMessages);
      // show error mesage
    }

  }

  render() {
    const { name, description } = this.state;
    const { loading, error } = this.props;
    return (
      <Dialog open={true} onClose={this.handleClose} disableBackdropClick>
        <DialogTitle> Create User Group </DialogTitle>
        <DialogContent>
          <If condition={loading}>
            <Loader />
          </If>
          <DialogContentText>
            { error }
          </DialogContentText>
          <form onSubmit={this.submitForm}>
            <Grid container direction="column">
              <Grid item>
                <Grid container>
                  <Grid item sm={4}>
                    <label>Group Name*</label>
                  </Grid>
                  <Grid item sm={8}>
                    <TextField name="name" value={name} onChange={this.fieldUpdateHandler}/>                    
                  </Grid>  
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item sm={4}>
                    <label>Description</label>
                  </Grid>
                  <Grid item sm={8}>
                    <TextField name="description" value={description} onChange={this.fieldUpdateHandler}/>                    
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}> Cancel </Button>
          <Button color="primary" onClick={this.submitForm} autoFocus> Create </Button>
        </DialogActions>
      </Dialog>
    );  
  }
}