import React from 'react';
import PropTypes from 'prop-types';

import './ModalMessage.scss';

function ModalMessage(props) {
  const {
    visible,
    children
  } = props;

  return (
    <div className={`modalMessage ${visible ? 'visible' : ''}`}>
      <div className='message'>
        {children}
      </div>
    </div>
  );
}

ModalMessage.propTypes = {
  visible: PropTypes.bool.isRequired
}

export default ModalMessage;