export const UAA_API_PREFIX = process?.env?.REACT_APP_UAA_PREFIX || 'uaa';
export const BE_API_PREFIX = process?.env?.REACT_APP_BE_PREFIX || 'v1';

export const BE_BASE_URL = process?.env?.REACT_APP_BE_ENDPOINT || '';

export const ENDPOINTS = {
  AUTH_LOGIN: 'login',
  BULK_OPERATION: 'bulk-operation',
  BULK_USERS: 'users',
  USER: 'user',
  AUTH_LOGOUT: 'logout',
  CONFIG: 'auth-info',
  USERS: 'Users',
  GROUPS: 'Groups'
};

const MILLI = 1;
const SECOND = 1000 * MILLI;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
export const BULK_OPERATION_TIMEOUT = 5 * HOUR;

export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_INTERNAL_ERROR = 500;
export const UAA_INVALID_TOKEN = 'invalid_token';
export const UAA_TOKEN_EXPIRED_DESCRIPTION = 'The token expired, was revoked, or the token ID is incorrect.';
export const UAA_CONNECTION_ERROR_SUBSTR = 'Failed to establish a new connection'

export const ERROR_TYPES = {
  TOKEN_EPIRED: 'token-expired',
  FORBIDDEN: 'forbidden',
  NETWORK_ERROR: 'network-error',
  UAA_CONNECTION: 'uaa-connection',
  RECOVERABLE_ERROR: 'recoverable-error'
};

export const NETWORK_ERROR_MESSAGE = 'Network Error';