import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTIONS } from '../userGroups.consts';
import { groupsApi } from '../../../services';
import { createUserGroupLoading, createUserGroupSuccess, createUserGroupFailure } from '../userGroups.actions';
import parseError from '../../__helpers__/parseApiError';

export function * createUserGroup(action) {
  try {
    yield put(createUserGroupLoading());
    const { data } = yield call(groupsApi.create, { data: action.data });
    yield put(createUserGroupSuccess(data));
  } catch (e) {
    const errorMessage = parseError(e);
    yield put(createUserGroupFailure(errorMessage));
  }
}

export default function * createUserGroupSaga() {
  yield takeEvery(ACTIONS.CREATE_USER_GROUP_REQUEST, createUserGroup);
}
