import { all, call, takeEvery, put } from 'redux-saga/effects';

import { loginApi, authStorage } from '../../../services'
import { ACTIONS } from '../auth.consts';
import * as authActions from '../auth.actions';

export function* loginRequest(action) {
  const { userId, userPassword } = action.payload;
  const loginPayload = {
    data: { userId, userPassword}
  };

  try {
    const { data } = yield call(loginApi.create, loginPayload);
    const {
      access_token: token,
      expires_in: expiresIn,
      scope
    } = data;
    yield all([
      put(authActions.loginSuccess({ token, scope, expiresIn })),
      put(authActions.persistAuth({ token, scope, expiresIn }))
    ]);

  } catch (error) {
    const { response: { status, data } } = error;
    if (status === 401) {
      // in this case, the error comes from uaa and BE has JSON.stringified it
      const message = JSON.parse(data.msg).error_description;
      yield put(authActions.loginFailure({ message }));
    } else {
      console.error(error);
      const message = 'Something went wrong, try again later.';
      yield put(authActions.loginFailure({ message }));
    }
  }
}

function* loginRequestSaga() {
  yield takeEvery(ACTIONS.LOGIN_REQUEST, loginRequest);
}

export function* persistAuth(action) {
  const { token, scope, expiresIn } = action.payload;
  authStorage.set({ token, scope, expiresIn });
  yield Promise.resolve();
}

function* persistAuthSaga() {
  yield takeEvery(ACTIONS.PERSIST_AUTH, persistAuth);
}

export default function* rootLoginSaga() {
  yield all([
    loginRequestSaga(),
    persistAuthSaga()
  ]);
}
