import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';
import PersistedState from './persisted.state';

// dev tools middleware
const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

  //saga middleware
const sagaMiddleware = createSagaMiddleware();

// store
const store = createStore(
  reducers,
  PersistedState(),
  composeSetup(
    applyMiddleware(sagaMiddleware)
  )
);

//start all sagas
sagas.forEach(sagaMiddleware.run);

export default store;
