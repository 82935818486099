import { connect } from 'react-redux';
import {
  createUserRequest,
  closeEditUserModal,
  groupsChange,
  newGroupsChange,
  editUserRequest
} from '../../../store/users/users.actions';

import EditUserModal from './EditUserModal';

const mapStateToProps = (state) => ({
  modalLoading: state.users.modalLoading,
  errorMessage: state.users.errorMessage,
  selectedRows: state.users.selectedRows,
  groupsLoading: state.users.groupsLoading,
  groups: state.users.groups,
  newGroups: state.users.newGroups
});

const mapDispatchToProps = {
  createUserRequest,
  closeEditUserModal,
  groupsChange,
  newGroupsChange,
  editUserRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserModal);