import { all, takeEvery, put } from 'redux-saga/effects';
import { ACTIONS as AUTH_ACTIONS } from '../../auth/auth.consts';
import * as configActions from '../config.actions';

export function* putReset() {
  yield put(configActions.reset());
}

function* resetConfig() {
  yield takeEvery(AUTH_ACTIONS.CLEAR_AUTH, putReset);
}

export default function* rootResetSaga() {
  yield all([
    resetConfig()
  ]);
}