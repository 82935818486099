import { all, call, takeEvery, put } from 'redux-saga/effects';
import {  authStorage, logoutApi } from '../../../services'
import { ACTIONS } from '../auth.consts';
import * as authActions from '../auth.actions';

export function* logoutRequest() {
  yield call(logoutApi.create);
  yield put(authActions.clearAuth());
}

function* logoutRequestSaga() {
  yield takeEvery(ACTIONS.LOGOUT, logoutRequest);
}

export function* clearAuth() {
  authStorage.clear();
  yield Promise.resolve();
}

function* clearAuthSaga() {
  yield takeEvery(ACTIONS.CLEAR_AUTH, clearAuth);
}

export default function* rootLogoutSaga() {
  yield all([
    logoutRequestSaga(),
    clearAuthSaga()
  ]);
}