import propTypes from 'prop-types';

function If ({ condition, children }) {
  if (condition) {
    return children;
  } else {
    return null;
  }
};

If.propTypes = {
  condition: propTypes.bool.isRequired
};

export default If;