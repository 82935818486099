import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import importReducer from './import/import.reducer';
import configReducer from './config/config.reducer';
import usersReducer from './users/users.reducer';
import userGroupReducer from './userGroups/userGroups.reducer';

import {PROPS as USERGROUP_PROPS} from './userGroups/userGroups.consts'

export default combineReducers({
  auth: authReducer,
  import: importReducer,
  config: configReducer,
  users: usersReducer,
  [USERGROUP_PROPS.ROOT]: userGroupReducer
});