import Axios from 'axios';
import path from 'path';

/**
 * POSTs to endpoint
 * @param {data, params } props
 * @description data is body, params is url params
 */
export function createApi({ data, params } = {}) {
  let url = path.join(this.endpointPrefix, this.endpoint);

  return Axios.request({
    url,
    method: 'POST',
    data,
    params,
    baseURL: this.baseURL,
    ...this.requestProps
  });
}
