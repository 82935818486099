import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTIONS } from '../userGroups.consts';
import { groupsApi } from '../../../services';
import { requestUserGroupListLoading, requestUserGroupListSuccess, requestUserGroupListFailure } from '../userGroups.actions';

export function * fetchUserGroups(action) {
  try {
    yield put(requestUserGroupListLoading());
    const { data } = yield call(groupsApi.getAll, { params: action.params });
    yield put(requestUserGroupListSuccess(data));
  } catch (e) {
    const error = e.response && e.response.data ? e.response.data.message : e.message;
    yield put(requestUserGroupListFailure(error));
  }
}

export default function * fetchUserGroupsSaga() {
  yield takeEvery(ACTIONS.USER_GROUPS_REQUEST, fetchUserGroups);
}
