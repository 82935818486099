import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@fh-components/button';

export default function BulkDeleteUserGroupModal ({
  selectedRow={},
  setDeleteUserGroupModal,
  setSelectedRow,
  deleteUserGroupRequest
}) {

  const handleClose = () => {
    setSelectedRow(null);
    setDeleteUserGroupModal(false);
  };

  const handleDelete = () => {
    deleteUserGroupRequest(selectedRow);
    handleClose();
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle> Delete User Groups </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the selected user groups?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}> No </Button>
        <Button onClick={handleDelete} color="primary" autoFocus> Yes </Button>
      </DialogActions>
    </Dialog>
  );
}