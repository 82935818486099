import React from 'react';
import MaterialTable from 'material-table';

import tableIcons from '../../TableIcons';
import groupsDatasource from './UsersList.GroupsList.datasource';
import { TABLE_OPTIONS, TABLE_COLUMNS } from './UsersList.GroupsList.consts';

function UsersListGroupsList(props) {
  //properties
  const {
    groups,
    totalGroups,
    isLoading
  } = props;

  //functions
  const {
    groupsPageRequest,
    groupsPageRequestSuccess
  } = props;

  const datasourceContext = {
    groups,
    groupsPageRequest,
    groupsPageRequestSuccess
  };

  return (
    <MaterialTable
      icons={tableIcons} isLoading={isLoading}
      data={groupsDatasource.bind(datasourceContext)}
      title={`${totalGroups} Group${totalGroups !== 1 ? 's' : ''}`}
      onSelectionChange={selection => handleSelectionChange(selection, props)}
      columns={TABLE_COLUMNS} options={TABLE_OPTIONS}
    />
  );
}

export const handleSelectionChange = (selection, props) => {
  //properties
  const {
    groupsChange
  } = props;

  //functions
  const {
    groupsCurrentPage,
    groups
  } = props;

  const selectionNames = selection.map(row => row.displayName);
  const currentPageSelections = groupsCurrentPage.filter(name => groups.includes(name));
  const deletions = currentPageSelections.filter(name => !selectionNames.includes(name));
  const additions = selectionNames.filter(name => !currentPageSelections.includes(name));

  const newGroups = [...groups, ...additions].filter(name => !deletions.includes(name));

  groupsChange({ groups: newGroups });
}

export default UsersListGroupsList;